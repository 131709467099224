import api from "../services/api"
import {MissionResponse, MissionProps} from '../utils/types/RealtorTypes'

const useMission = async (params: MissionProps): Promise<MissionResponse> => {
    try{
        const formData = formatFormData(params);

        const res = await api.post('/realtor/mission', formData ,
        {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }

        );
        return res.data;
    } catch {
        return {} as MissionResponse
    }
}
const formatFormData = (params: MissionProps) => {
    const formData = new FormData();
    Object.entries(params).forEach(param => {
      const [ key, value ] = param;
      if (typeof value !== 'undefined' && value !== null) {
        if (Array.isArray(value)) {
          value.forEach(item => formData.append(key, String(item)));
        } else {
          formData.append(key, value);
        }
      }
    })
    return formData;
}
export default useMission;