import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M15 1.5H3c-.75 0-1.5.675-1.5 1.5v2.258c0 .54.323 1.005.75 1.267V15c0 .825.825 1.5 1.5 1.5h10.5c.675 0 1.5-.675 1.5-1.5V6.525c.427-.263.75-.728.75-1.268V3c0-.825-.75-1.5-1.5-1.5zM14.25 15H3.75V6.75h10.5V15zM15 5.25H3V3h12v2.25z"
        fill="#fff"
      />
      <path d="M11.25 9h-4.5v1.5h4.5V9z" fill="#fff" />
    </svg>
  )
}

export default SvgComponent
