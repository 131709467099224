import React from 'react';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes';
import './styles/global.scss';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardContextProvider } from './context/DashboardContext';

function App() {
  return (
    <DashboardContextProvider>
      <AppRoutes />
      <ToastContainer limit={1} />
    </DashboardContextProvider>
  );
}

export default App;
