/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Container } from "./styles";
import { formatDataToCSVSynthetic } from "../../../utils/exportsCSV";
import { format } from "date-fns";
import Table from "../../Table";
import { ResponseReport } from "../../../interfaces/ResponseReport";
import { calcValuesDotz } from "../../../utils/calcValuesDotz";

interface Props {
    dataReport: ResponseReport[];
    isLoading: boolean;
}

const columns = [
    { title: "Cod. Coligada", attribute_name_tbody: "cod_coligada" },
    { title: "Coligada", attribute_name_tbody: "name_coligada" },
    { title: "Empreendimento", attribute_name_tbody: "enterprise" },
    { title: "Cod. Departamento", attribute_name_tbody: "cod_department" },
    { title: "Departamento", attribute_name_tbody: "name_department" },
    { title: "Desconto DOTZ", attribute_name_tbody: "discount_dotz" },
    { title: "Receita DOTZ", attribute_name_tbody: "income_dotz" },
    { title: "Extrato", attribute_name_tbody: "total" },
];

const TableReportSynthetic = ({ dataReport, isLoading }: Props) => {
    const handleDataByEnterprise = (param: ResponseReport[]) => {
        const data: ResponseReport[] = [];
        param.forEach(rp => {
            if (!data.some(i => i.enterprise === rp.enterprise)) {
                data.push(rp);
            } else {
                const index = data.findIndex(i => i.enterprise === rp.enterprise);
                
                const item = data[index];
                data[index] = { ...item, discount_dotz: String(Number(item.discount_dotz) + Number(rp.discount_dotz)) }
            }
        });
        
        return data;
    }
    
    const [data, setData] = useState(calcValuesDotz(dataReport));
    const [dataCSV, setDataCSV] = useState<any[]>([]);

    useEffect(() => {
        const dataInitial = handleDataByEnterprise(dataReport) ?? [];
        setData(calcValuesDotz(dataInitial));
        setDataCSV(formatDataToCSVSynthetic(dataInitial));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataReport])

    return (
        <Container>
            <div className="table">
                <div>
                    <h2>Sintético</h2>
                </div>
                <div className="data-export">
                    <CSVLink filename={"Sintetico " + format(new Date(), "dd-MM-yyyy-HH-mm")} data={dataCSV}>Exportar CSV</CSVLink>
                </div>
            </div>
            <div className="content">
                <Table data={data} columns={columns} isLoading={isLoading} offset={15} />
            </div>
        </Container>
    )
}

export default TableReportSynthetic;