import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 2rem;
  width: 100%;

  div.content{
    width: 100%;
    overflow-x: auto;
  }
  
  div.table{
    box-shadow: 0 -3px 10px 0px rgba(29,25,57,0.03);
    border-radius: 0.48rem 0.48rem 0 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 1rem;
    width: 100%;
    
    div.data-export{
      padding: 0.5rem 1rem;
      background-color: #D60B52;
      border-radius: 0.24rem;

      a{
        color: #fff;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }

  .date-filter{
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
    gap: 1rem;

    label {
      font-family: Montserrat;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      white-space: nowrap
    }

    input[type = "date"]{
      padding: 0.5rem 0.8rem;
      border: none;
      color: var(--blue);
      border-radius: 0.24rem;
    }

    div.react-datepicker-wrapper{
      display: flex;
    }

    div.react-datepicker__input-container{
      > input{
        min-height: 34px;
        padding: 0.5rem;
        border: none;
        color: var(--blue);
        border-radius: 0.24rem;
        border-radius: 1.5rem;
        background:  rgba(160, 164, 177, 0.10);
      }
    }
  }
`;
