import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField ,} from "@material-ui/core";
import { FiSearch } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import { Container, DataContainer, FilterContainer } from "./styles";
import ListDataItem from "../../../components/ListDataItem";
import BreadcrumbsDashboard from "../../../components/BreadcrumbsDashboard";
import Button from "../../../components/Button";
import { useDashboard } from "../../../context/DashboardContext";
import { fetchAnnouncement, fetchFile, fetchMission } from "../../../hooks/useFetch";
import { CategoriesProps, Enterprise, DataProps } from "../../../utils/types/RealtorTypes";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import {MdOutlineMoodBad} from "react-icons/md";

const ListData = () => {
  const [selectedEnterprise, setSelectedEnterprise] = useState<{ cod_enterprise: number, name_enterprise: string }>({} as {cod_enterprise: number, name_enterprise: string})
  const [enterprises, setEnterprises] = useState([] as Enterprise[])
  const { isGeneral, breadCrumbsName, typeList, fetchEnterprises, fetchCategories, setIsEdit } = useDashboard()
  const [data, setData] = useState<DataProps>({} as DataProps)
  const [search, setSearch] = useState('');
  const [category, setCategories] = useState([] as CategoriesProps[])
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState(0); // Estado para controlar a página atual
  const [totalPages, setTotalPages] = useState(0);
  const [missionActive, setMissionActive] = useState<undefined | boolean>(undefined)
  const navigate = useNavigate(); 

  const handleSelectedEnterprise = (e: React.ChangeEvent<{ value: unknown }>) => {
    if(e.target.value !== 0 ) {
      setSelectedEnterprise(enterprises.find(enterprise => enterprise.cod_enterprise === e.target.value) || {cod_enterprise: 0, name_enterprise: 'todos'} )
    } else {
      setSelectedEnterprise({cod_enterprise: 0, name_enterprise: 'todos'})
    }
  }

  const crumbs = [
    {name: 'MARKETING', url: '/marketing'},
    {name: breadCrumbsName, url: '/marketing/list-data'}
  ]
  useEffect(() => {
    if (typeList === 'mission'){
      if(isGeneral){
        fetchMission({search, cod_enterprise: 'null', current_page: currentPage + 1, category_id: selectedCategory === 0 ? undefined : selectedCategory, mission_active: missionActive}).then((data) => {
          setTotalPages(data.total_page)
          setData(data)
        })
      } else {
        fetchMission({search, cod_enterprise: selectedEnterprise.cod_enterprise ? selectedEnterprise.cod_enterprise : 0 , current_page: currentPage + 1, category_id: selectedCategory, mission_active: missionActive}).then((data) => {
          setTotalPages(data.total_page)
          setData(data)
        })
      }
    }
    if (typeList === 'file'){
      if(isGeneral){
        fetchFile({search, cod_enterprise: 'null', current_page: currentPage + 1, category_id: selectedCategory === 0 ? undefined : selectedCategory}).then((data) => {
          setTotalPages(data.total_page)
          setData(data)
        })
      } else {
        fetchFile({search, cod_enterprise: selectedEnterprise.cod_enterprise ? selectedEnterprise.cod_enterprise : 0, current_page: currentPage + 1, category_id: selectedCategory === 0 ? undefined : selectedCategory}).then((data) => {
          setTotalPages(data.total_page)
          setData(data)
        })
      }
    }
    if (typeList === 'announcement'){
      if(isGeneral){
        fetchAnnouncement({search,  cod_enterprise: 'null', current_page: currentPage + 1}).then((data) => {
          setTotalPages(data.total_page)
          setData(data)
        })
      } else {
        fetchAnnouncement({search, cod_enterprise: selectedEnterprise.cod_enterprise ? selectedEnterprise.cod_enterprise : 0, current_page: currentPage + 1}).then((data) => {
          setTotalPages(data.total_page)
          setData(data)
        })
      }
    }
  }, [search, selectedEnterprise, selectedCategory, currentPage, missionActive])

useEffect(() => {
  setCurrentPage(0)
}, [search, selectedEnterprise, selectedCategory, missionActive])

useEffect(() => {
  fetchEnterprises().then((data) => {setEnterprises(data)})
    fetchCategories({type: typeList}).then((data) => setCategories(data))
}, [])

const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  setSearch(event.target.value)
}

const handleCategory = (event: React.MouseEvent<HTMLButtonElement>) => {
  setSelectedCategory(Number(event.currentTarget.value))
}
const handlePageChange = (selectedPage: any) => {
  setCurrentPage(selectedPage.selected);
};
const handleNavigation = () => {
  setIsEdit(false);

  if(typeList === 'mission'){
    navigate('/marketing/manage-content/mission')
  }
  if(typeList === 'announcement'){
    navigate('/marketing/manage-content/announcement')
  }
  if(typeList === 'file'){
    navigate('/marketing/manage-content/archive')
  }
 
}
 return (
  <Container>
    <BreadcrumbsDashboard  crumbs={crumbs} />

    <div className="header-button">
      <p className="title">
        {typeList === 'mission' && !isGeneral && 'Missões por empreendimento'}
        {typeList === 'mission' && isGeneral && 'Missões gerais'}
        {typeList === 'file' && !isGeneral && 'Arquivos por empreendimento'}
        {typeList === 'file' && isGeneral && 'Arquivos gerais'}
        {typeList === 'announcement' && !isGeneral && 'Comunicados por empreendimento'}
        {typeList === 'announcement' && isGeneral && 'Comunicados gerais'}

      </p>
    <Button onClick={handleNavigation} style={{alignSelf: 'end'}}>+ ADICIONAR {typeList === 'mission' ? 'NOVA MISSÃO' : typeList === 'file' ? 'NOVO ARQUIVO' : typeList === 'announcement' && 'NOVO COMUNICADO'}</Button>
    </div>

    <TextField
      label='Search'
      variant="outlined"
      onChange={handleSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <FiSearch />
          </InputAdornment>
        )
      }}  
    />

    <FormControl
      variant="outlined"
      style={{display: isGeneral ? 'none': 'flex'}}
    >
      
          <InputLabel id="demo-simple-select-outlined-label">Selecione o empreendimento</InputLabel>

      
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={selectedEnterprise.name_enterprise}
        onChange={handleSelectedEnterprise}
        // defaultValue={{cod_enterprise: 0, name_enterprise: 'todos'}}
        label="Selecione o empreendimento"
        >
          
         <MenuItem value={0}>Todos os empreendimentos</MenuItem>
        {enterprises.map(enterprise => (
          <MenuItem key={enterprise.cod_enterprise} value={enterprise.cod_enterprise}>{enterprise.name_enterprise}</MenuItem>
          ))}
      </Select>
    </FormControl>

    <FilterContainer>
      {category && category.map(category => (
          <button key={category.id} value={category.id} onClick={handleCategory}>
            {category.category_name}
          </button>
        ))
      }
      {typeList === 'mission' || typeList == 'file' &&
      <button value={undefined} onClick={handleCategory}>Todos</button>
      }
      {typeList === 'mission' &&
        <>
          <button value={undefined} onClick={() => setMissionActive(true)}>Missões ativas</button>
          <button value={undefined} onClick={() => setMissionActive(false)}>Missões Encerradas</button>
        </>
      }
     
    </FilterContainer>
      
    <DataContainer>

      {data.data && data.data.length > 0 ? data.data.map((itemData) => (
        <ListDataItem key={itemData.id} itemData={itemData} />
      ))
      :
      <div className="not-found">
        <MdOutlineMoodBad style={{width: 40, height:40, fill: '#666666'}}/>
        <p>Não encontramos nenhum resultado.</p>
      </div>
    
    }
      
    </DataContainer>
    <div className="pagination-box">
      <ReactPaginate
          previousLabel={''}
          nextLabel={''}
          pageCount={totalPages}
          onPageChange={handlePageChange}
          forcePage={currentPage} // Informe ao componente a página atual
          containerClassName={'paginate'}
          activeClassName={'active'}
        />
    </div>
    
  </Container>
 ) 
}

export default ListData;