
const SvgComponent = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      {...props}
    >
      <path
        d="M46.667 53.063c.706 0 1.283-.591 1.283-1.313V28.125h-6.417c-1.42 0-2.566-1.173-2.566-2.625v-6.563H26.133c-.706 0-1.283.591-1.283 1.313v31.5c0 .722.577 1.313 1.283 1.313h20.534zM21 20.25c0-2.896 2.302-5.25 5.133-5.25h13.275c1.363 0 2.67.55 3.633 1.534l7.26 7.424a5.313 5.313 0 011.499 3.716V51.75c0 2.896-2.302 5.25-5.133 5.25H26.133C23.302 57 21 54.646 21 51.75v-31.5zm6.417 18.375c0-1.452 1.147-2.625 2.566-2.625h7.7c1.42 0 2.567 1.173 2.567 2.625v1.313l3.601-2.453a1 1 0 01.546-.172c.545 0 .986.45.986 1.008v8.483a.997.997 0 01-.986 1.008.937.937 0 01-.546-.172l-3.601-2.453V46.5c0 1.452-1.147 2.625-2.567 2.625h-7.7c-1.42 0-2.566-1.173-2.566-2.625v-7.875z"
        fill="#666666"
      />
    </svg>
  )
}

export default SvgComponent
