import Button from "../../Button";
import Spinner from "../../Spinner";
import { ContainerModal } from "./style";

interface Props{
    data: any;
    handleSubmit: (e: any) => Promise<void>;
    handleChange: (a: string, b: string) => void;
    isLoading: boolean;
}

export const ContentModal = ({ data, handleSubmit, handleChange, isLoading }: Props) => (
    <ContainerModal>
        <form onSubmit={handleSubmit}>
            <label htmlFor="name">Nome</label>
            <input value={data?.name ?? ''} onChange={(e) => handleChange(e.target.value, "name")} required minLength={8} type="text" id="name" name="name" />

            <label htmlFor="email">Email</label>
            <input value={data?.email ?? ''} onChange={(e) => handleChange(e.target.value, "email")} required minLength={8} type="email" id="email" name="email" />

            <div className="grouped">
                <div className="labels">
                    <label htmlFor="cpf">CPF</label>
                    <input value={data?.cpf ?? ''} onChange={(e) => handleChange(e.target.value, "cpf")} required minLength={8} type="text" id="cpf" name="cpf" />
                </div>
                <div className="labels">
                    <label htmlFor="phone_number">Telefone</label>
                    <input value={data?.phone_number ?? ''} onChange={(e) => handleChange(e.target.value, "phone_number")} required minLength={8} type="phone" id="phone_number" name="phone_number" />
                </div>
            </div>

            <Button type="submit" disabled={isLoading}>
                {
                    !isLoading ? 'Salvar' : <Spinner type="secondary" />
                }
            </Button>
        </form>
    </ContainerModal>
);