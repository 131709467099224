import Main from "../../components/Reports/Main";

const Home = () => {
    return (
        <div>
            <Main />
        </div>
    )
}

export default Home;