const SvgComponent = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      {...props}
    >
      <path
        d="M26.133 53.063c-.706 0-1.283-.591-1.283-1.313v-31.5c0-.722.577-1.313 1.283-1.313h12.834V25.5c0 1.452 1.147 2.625 2.566 2.625h6.417V51.75c0 .722-.578 1.313-1.283 1.313H26.133zm0-38.063C23.302 15 21 17.354 21 20.25v31.5c0 2.896 2.302 5.25 5.133 5.25h20.534c2.831 0 5.133-2.354 5.133-5.25V27.674a5.313 5.313 0 00-1.5-3.716l-7.267-7.424A5.06 5.06 0 0039.408 15H26.133zm5.775 17.063c-1.066 0-1.925.877-1.925 1.968v13.125c0 1.091.859 1.969 1.925 1.969 1.067 0 1.925-.878 1.925-1.969v-2.625h3.53c3.368 0 6.095-2.789 6.095-6.234s-2.727-6.234-6.096-6.234h-5.454zm5.454 8.53h-3.529V36h3.53c1.243 0 2.245 1.025 2.245 2.297 0 1.271-1.002 2.297-2.245 2.297z"
        fill="#666666"
      />
    </svg>
  )
}

export default SvgComponent
