import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  gap: 20px;

  h1 {
    color: var(--blue);
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  p {
    color: #666666;
    font-size: 16px;
    line-height: 32px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--magenta);
    border-radius: 4px;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 16px;
    border: 0;
    cursor: pointer;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    text-decoration: none;
    color: var(--blue);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ms = makeStyles({
  card: {
    padding: 16,
    border: 1,
    borderColor: '#666666',
    borderStyle: 'solid',
    borderRadius: 8,
    boxShadow: 'none',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  },
  buttonDiv: {
    display: 'flex',
    gap: 30
  }
})

