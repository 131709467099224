import { Container } from "./style";
import { GoSignOut } from 'react-icons/go'
import { useDashboard } from "../../context/DashboardContext";
import { BsChevronRight } from "react-icons/bs";

interface Props{
    setIsShowSidebar: () => void;
    isShowSidebar: boolean;
}

const TopBar = ({ setIsShowSidebar, isShowSidebar }: Props) => {
    const { signOut } = useDashboard();
    return(
        <Container isShowSidebar={isShowSidebar}>
            <div>
                <button onClick={setIsShowSidebar}>
                    <BsChevronRight />
                </button>
            </div>
            <div>
                <button onClick={signOut}>
                    Sair
                    <GoSignOut />
                </button>
            </div>
        </Container>
    )
}

export default TopBar;