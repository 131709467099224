import { FormControl, TextField, RadioGroup, FormControlLabel, Radio, InputLabel, Select, MenuItem, FormGroup, Box, Modal, Checkbox } from "@material-ui/core";
import { MdOutlineDescription } from "react-icons/md";
import BreadcrumbsDashboard from "../../../../components/BreadcrumbsDashboard";
import { Container, ButtonAlt, ImageWrapper, ButtonArticle, ButtonGroup, IconWrapper, BoldP, ButtonAdd } from "./styles";
import { useEffect, useState } from "react";
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { FiEdit } from "react-icons/fi";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { useDashboard } from "../../../../context/DashboardContext";
import useMission from "../../../../hooks/useMission";
import {BiBell} from "react-icons/bi"
import { Toast } from "../../../../components/Toast";
import { useNavigate } from "react-router-dom";
import {BsCheck2Circle} from "react-icons/bs"
import useEditMission from "../../../../hooks/useEditMission";
import api from "../../../../services/api";
import { CategoriesProps, Enterprise } from "../../../../utils/types/RealtorTypes";
import { addDays } from "date-fns";
import {AiOutlinePlus} from "react-icons/ai"
import {GrDocumentText} from "react-icons/gr"
import { getUrlAwsS3Realtor } from "../../../../utils/aws";
import { TextArea } from "../../../../components/Description";

const Mission = () => {
  const navigate = useNavigate();
  const { selectedData, fetchEnterprises, isEdit, setTypeList, fetchCategories, setIsGeneral, setBreadCrumbsName } = useDashboard()
  const isEdit2 = isEdit
  const { width } = useWindowDimensions();
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width && width < 540 ? '300px' : '540px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
  };
  const crumbs = [
    { name: 'MARKETING', url: '/marketing' },
    { name: isEdit ? 'Editar missão' : 'Adicionar nova missão', url: '/marketing/manage-content/mission' }
  ]
  const [isActive, setIsActive] = useState(isEdit2 ? selectedData.mission_active === true ? 'Ativo' : 'Encerrado' : '');
  const [selectedCategory, setSelectedCategory] = useState(isEdit2 ?  selectedData.categories && selectedData.categories.length > 0 ? [selectedData.categories[0].id] : [] : [] );
  const [hasBuilding, setHasBuilding] = useState(isEdit2 ? selectedData.enterprise !== null ? 'sim' : 'nao' : '')
  const [isPublic, setIsPublic] = useState(isEdit2 ? selectedData.is_public === true ? 'publico' : 'privado' : '');
  const [enterprises, setEnterprises] = useState([] as Enterprise[])
  const [selectedEnterprise, setSelectedEnterprise] = useState<{ cod_enterprise: number, enterprise_code?: number, name_enterprise: string, enterprise_name?: string }>(selectedData.enterprise || {} as { cod_enterprise: number, name_enterprise: string, enterprise_code?: number, enterprise_name?: string })
  const [urlThumbNail, setUrlThumbNail] = useState<string>('');
  const [images, setImages] = useState([] as ImageListType);
  const [imageEdit, setImageEdit] = useState(isEdit2 ? selectedData.thumbnail_url : '');
  const [imageEditNew, setImageEditNew] = useState({} as ImageListType)
  const [regulationEdit, setRegulationEdit] = useState(isEdit2 ? selectedData.regulation_url : '')
  const [title, setTitle] = useState<string>(isEdit2 ? selectedData.title ? selectedData.title : '' : '');
  const [inicialDate, setInicialDate] = useState<string>(isEdit2 && selectedData.start_mission !== undefined ? new Date(selectedData.start_mission).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]);
  const [finalDate, setFinalDate] = useState<string>(isEdit2 && selectedData.end_mission !== undefined ? new Date(selectedData.end_mission + '').toISOString().split('T')[0]: new Date().toISOString().split('T')[0]);
  const [description, setDescription] = useState<string>(isEdit2 ? selectedData.description ? selectedData.description : '' : '');
  const [selectedFile, setSelectedFile] = useState<File>({} as File);
  const [isFilePicked, setIsFilePicked] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isValidTitle, setIsValidTitle] = useState<boolean>(true)
  const [isValidDescription, setIsValidDescription] = useState<boolean>(true)
  const [isValidOption, setIsValidOption] = useState<boolean>(true)
  const [isValidRelated, setIsValidRelated] = useState<boolean>(true)
  const [isValidImage, setIsValidImage] = useState<boolean>(true)
  const [isValidActive, setIsValidActive] = useState<boolean>(true)
  const [validationToast, setValidationToast] = useState<boolean>(false)
  const [draftToast, setDraftToast] = useState<boolean>(false)
  const [toastNotification, setToastNotification] = useState(false);
  const [saveToast, setSaveToast] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [isValidFile, setIsValidFile] = useState<boolean>(true)
  const [categories, setCategories] = useState<CategoriesProps[]>([] as CategoriesProps[])
  const [disabledButtons, setDisabledButtons] = useState<boolean>(false);
  const maxNumber = 1;
  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.value)
  };
  const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory([Number(event.target.value)] || [] as number[])
  };
  
  const handleChangeBuilding = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasBuilding(event.target.value)
  };
  const handleChangePublic = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPublic(event.target.value)
  };
  
  const handleSelectedEnterprise = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedEnterprise(enterprises.find(enterprise => enterprise.cod_enterprise === e.target.value) || {} as { cod_enterprise: number, name_enterprise: string })
  }

  const onChange = (imageList: ImageListType) => {
    setImages(imageList as never[]);
  };

  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }

  const handleDescription = (value: string) => {
    setDescription(value)
  }

  const handleInicialDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInicialDate(event.target.value)
  }

  const handleFinalDate = (event: React.ChangeEvent<HTMLInputElement>) => {

    setFinalDate(event.target.value)
  }

  const fileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    }
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsDelete(false);
  };
  const handleDelete = async () => {
    setIsDelete(true);
      try{
        await api.delete(`/realtor/mission/${selectedData.id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
        })
        setTimeout(function() {
          navigate('/marketing/list-data');
        }, 2500);
      } catch{
        return false
      }
  }
  useEffect(() => {
    if (!isValidRelated && hasBuilding.trim().length > 0) {
      setIsValidRelated(true);
    } else if (!isValidRelated && hasBuilding.trim().length === 0) {
      setIsValidRelated(false);
    } 
    if(!isValidOption && isPublic.trim().length > 0){
      setIsValidOption(true);
    } else if (!isValidOption && isPublic.trim().length === 0){
      setIsValidOption(false);
    }
    if(!isValidTitle && title.trim().length > 0){
      setIsValidTitle(true);
    } else if (!isValidTitle && title.trim().length === 0){
      setIsValidTitle(false);
    }
    if(!isValidImage && (images.length > 0 || imageEdit)){
      setIsValidImage(true);
    } else if (!isValidImage && (images.length === 0 || !imageEdit)){
      setIsValidImage(false);
    }
    if(!isValidFile && (isFilePicked || regulationEdit)){
      setIsValidFile(true);
    } else if (!isValidFile && (isFilePicked || !regulationEdit)){
      setIsValidFile(false);
    }
  }, [isValidOption, isValidRelated, isPublic, hasBuilding, isValidTitle, title, images, imageEdit, isValidImage, isValidFile, isFilePicked, regulationEdit])
  const setSrcImage = async () => {
    const url = await getUrlAwsS3Realtor(selectedData.thumbnail_url ?? '', selectedData.thumbnail_name ?? '', selectedData.is_public);
    setUrlThumbNail(url);
  }

  useEffect(() => {
    setSrcImage()
  }, [])
  
  useEffect(() => {
    fetchEnterprises().then((data) => {setEnterprises(data)})
    fetchCategories({type: 'mission'}).then((data) => setCategories(data))
  }, [] )
 
  const onSubmitForm = async (event: React.MouseEvent, typeSubmit: string) => {
    event.preventDefault();
    let isValidForm = true;

    if (title.trim().length === 0) {
      setIsValidTitle(false);
      isValidForm = false;
    } else {
      setIsValidTitle(true);
    }

    if (description.trim().length === 0) {
      setIsValidDescription(false);
      isValidForm = false;
    } else {
      setIsValidDescription(true);
    }

    if (hasBuilding.trim().length === 0) {
      setIsValidRelated(false);
      isValidForm = false;
    } else {
      setIsValidRelated(true);
    }

    if (isPublic.trim().length === 0) {
      setIsValidOption(false);
      isValidForm = false;
    } else {
      setIsValidOption(true);
    }
    
    if(isEdit){
      if(imageEdit || images){
        setIsValidImage(true);
      } else {
        setIsValidImage(false)
        isValidForm = false;
      }

      if(isFilePicked || regulationEdit){
        setIsValidFile(true);
      }else{
        setIsValidFile(false);
        isValidForm = false;
      }
    } else {
      if (!isFilePicked) {
        setIsValidFile(false);
        isValidForm = false;
      } else {
        setIsValidFile(true);
      }
      if (images.length === 0) {
        setIsValidImage(false);
        isValidForm = false;
      } else {
        setIsValidImage(true);
      }
  
    }
    
    if (isValidForm) {
      setDisabledButtons(true)
      if(typeSubmit === 'saveDraft' || typeSubmit === 'publish'){
      Promise.all([
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useMission({
          file: images[0].file,
          thumbnail: images[0].file,
          regulation: selectedFile,
          start_mission: inicialDate,
          end_mission: finalDate,
          title: title,
          description: description,
          cod_enterprise: selectedEnterprise.cod_enterprise,
          categories_ids: selectedCategory.map(Number),
          type: 'mission',
          is_draft: typeSubmit === 'saveDraft' ? true : false,
          is_public: isPublic === 'publico' ? true : false
        })
      ])
        .then(() => {
          
          setToastNotification(true);
          if(typeSubmit === 'saveDraft'){
            setDraftToast(true)
          }
          if(typeSubmit === 'publish'){
            setValidationToast(true)
          }
          
          setTimeout(function() {
            navigate('/marketing/list-data');
          }, 2500);
          
        })
        if(hasBuilding === 'nao'){
          setIsGeneral(true)
          setBreadCrumbsName('MISSÕES GERAIS')
        } else {
          setIsGeneral(false)
          setBreadCrumbsName('MISSÕES POR EMPREENDIMENTO')
        }
        setTypeList('mission')
    } else if(typeSubmit === 'saveEdit'){
      Promise.all([
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEditMission({
          id: selectedData.id,
          file: images && images.length > 0 ? images[0].file : imageEdit,
          thumbnail: images && images.length > 0 ? images[0].file : imageEdit,
          regulation: selectedFile ? selectedFile : regulationEdit,
          start_mission: inicialDate,
          end_mission: disabled ? addDays(new Date(), -1) + '' : finalDate,
          title: title,
          description: description,
          cod_enterprise: hasBuilding  === 'sim' ? (selectedEnterprise.cod_enterprise || selectedEnterprise.enterprise_code) : null,
          categories_ids: selectedCategory.map(Number),
          type: 'mission',
          is_public: isPublic === 'publico' ? true : false
        })
      ])
        .then(() => {
          setToastNotification(true);
          setSaveToast(true)
          setTimeout(function() {
            navigate('/marketing/list-data');
          }, 2500);
          
        })
        if(hasBuilding === 'nao'){
          setIsGeneral(true)
          setBreadCrumbsName('MISSÕES GERAIS')
        } else {
          setIsGeneral(false)
          setBreadCrumbsName('MISSÕES POR EMPREENDIMENTO')
        }
        setTypeList('mission')
    } else if(typeSubmit === 'publishEdit'){
      Promise.all([
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEditMission({
          id: selectedData.id,
          file: images && images.length > 0 ? images[0].file : imageEdit,
          thumbnail: images && images.length > 0 ? images[0].file : imageEdit,
          regulation: selectedFile ? selectedFile : regulationEdit,
          start_mission: inicialDate,
          end_mission: disabled ? addDays(new Date(), -1) + '' : finalDate,
          title: title,
          description: description,
          cod_enterprise: hasBuilding  === 'sim' ? (selectedEnterprise.cod_enterprise || selectedEnterprise.enterprise_code) : null,
          categories_ids: selectedCategory.map(Number),
          type: 'mission',
          is_draft: false,
          is_public: isPublic === 'publico' ? true : false
        })
      ])
        .then(() => {
          setToastNotification(true);
          setValidationToast(true)
          
          setTimeout(function() {
            navigate('/marketing/list-data');
          }, 2500);
        })
        if(hasBuilding === 'nao'){
          setIsGeneral(true)
          setBreadCrumbsName('MISSÕES GERAIS')
        } else {
          setIsGeneral(false)
          setBreadCrumbsName('MISSÕES POR EMPREENDIMENTO')
        }
        setTypeList('mission')
    }
    setSaveToast(false)
    setToastNotification(false);
    setValidationToast(false);
    setDraftToast(false);
  }
  
  };
  return (
    <Container>
      {toastNotification && 
        <div id="toast-container2">
        <Toast Icon={validationToast || draftToast || saveToast ? BsCheck2Circle : BiBell} onClose={() => setToastNotification(!toastNotification)} status={validationToast || saveToast ? 'success' : draftToast ? 'draft' : ''} >
          <p>
            {validationToast && 'Sua missão foi publicada com sucesso'}
            {draftToast && 'Sua missão foi salva como rascunho'}
            {saveToast && 'Sua missão foi salva com sucesso'}

          </p>
        </Toast>
        </div>
      
      }
      <div>
        <BreadcrumbsDashboard crumbs={crumbs} />
      </div>
      <p className="title" style={{fontSize: 20, textTransform: 'uppercase'}}>{isEdit2 ? 'Editar' : 'Adicionar nova'} Missão</p>
      <div className="div-banner">

        <div className="top-banner">
          <div className="esquerda">
            <p>Banner</p>
            <p>Será mostrado no início da página da Missão</p>
          </div>
          <div className="direita">
            <p>Dimensões: 1216x384px / Área de Segurança: 640x384px</p>
            <p>Tamanho máximo: 1MB</p>
          </div>
        </div>


        <div className="image">
          {imageEdit ? 
          <ImageUploading
          acceptType={['jpg', 'jpeg', 'png']}
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
          maxFileSize={1000000}
        >
          {({
            imageList,
            onImageUpload,
            onImageUpdate,
            isDragging,
            dragProps,
            errors
          }) => (

            <div className="upload__image-wrapper" {...dragProps} >

              
                <ImageWrapper>

                  
                    <>
                      <img className="" alt="edit" src={imageList[0] ? imageList[0]['data_url'] : urlThumbNail} />
                      <div className="image-item__btn-wrapper">

                        <button onClick={() =>{onImageUpload(); setImageEditNew(imageList)}}>
                          <FiEdit width="48" height="48" />
                        </button>

                      </div>
                    </>
                  
                </ImageWrapper>
              
              {errors?.maxFileSize && <span>A imagem é muito grande, tente subir um arquivo com no máximo 1 mb.</span>}
            </div>
          )}
        </ImageUploading>
          : 
          <ImageUploading
            acceptType={['jpg', 'jpeg', 'png']}
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
            maxFileSize={1000000}
          >
            {({
              imageList,
              onImageUpload,
              onImageUpdate,
              isDragging,
              dragProps,
              errors
            }) => (

              <div className="upload__image-wrapper" {...dragProps} >

                {images.length === 0 ?
                <>
                  <ButtonAdd onClick={onImageUpload}><AiOutlinePlus />SELECIONAR ARQUIVO DO SEU DISPOSITIVO</ButtonAdd>
                </>

                  :
                  <ImageWrapper>

                    {imageList.map((image, index) => (
                      <>
                        <img key={index} className="" alt="add" src={image['data_url']} />
                        <div className="image-item__btn-wrapper">

                          <button onClick={() => onImageUpdate(index)}>
                            <FiEdit width="48" height="48" />
                          </button>

                        </div>
                      </>
                    ))}
                  </ImageWrapper>
                }
                {errors?.maxFileSize && <span>A imagem é muito grande, tente subir um arquivo com no máximo 1 mb.</span>}
              </div>
            )}
          </ImageUploading>
          }
        </div>
      </div>
      {!isValidImage && <p className="error">É obrigatório adicionar uma imagem para o banner</p>}

      <div className="form">
        <FormControl>
          <div className="form-inputs">
            <p className="title">Preencha as informações a seguir:</p>
            <TextField className="titlefield" id="outlined-size-small" label="Título da missão" variant="outlined" fullWidth value={title} onChange={handleTitle} inputProps={{ maxLength: 50 }} />
            {!isValidTitle && <p className="error">Campo obrigatório</p>}
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <span className="title">Selecione a data de validade da missão:</span>
              <span className="date-label">A missão será encerrada automaticamente na data final.</span>
            </div>
            <div className="dates">
              <div>
                <label htmlFor="date2">Data inicial</label>
                <input className="dateinput" value={inicialDate} defaultValue={inicialDate} onChange={handleInicialDate} id="date2" name="date2" type="date" required disabled={disabled === true}></input>
              </div>
              <div>
                <label htmlFor="date2">Data final</label>
                <input className="dateinput" value={finalDate} defaultValue={finalDate} onChange={handleFinalDate} id="date2" name="date2" type="date" required disabled={disabled === true}></input>
              </div>

            </div>

            <TextArea id="outlined-size-small" label="Descrição da missão" onChange={handleDescription} />
            {!isValidDescription && <p className="error">Campo Obrigatório</p>}

            <p className="title">Regulamento</p>
            <span className="regulation-label">Adicione um arquivo .pdf para que os corretores possam acessar o regulamento completo</span>
         {regulationEdit?.length ? 
            <div>
              {!isFilePicked ?
                <div className="attachedfile">
                  {selectedData.regulation_name}
                  <label className="label2" htmlFor="file"><FiEdit /></label>
                  <input id="file" type="file" accept=".pdf" name="file" style={{ visibility: "hidden" }} onChange={fileHandler} />
                </div>
            :
              <>
                <div className="attachedfile">
                  {selectedFile.name}
                  <label className="label2" htmlFor="file"><FiEdit /></label>
                  <input id="file" type="file" accept=".pdf" name="file" style={{ visibility: "hidden" }} onChange={fileHandler} />
                </div>
              </>
            }
            
            </div>
            : 
            <div>
            {!isFilePicked ?
              <>
                <div className="pdfbutton">
                  <GrDocumentText fill="#666666" style={{fill: '#666666'}}></GrDocumentText>
                  <label className="label1" htmlFor="file"> Anexar Regulamento</label>
                </div>
                <input id="file" type="file" accept=".pdf" name="file" style={{ visibility: "hidden" }} onChange={fileHandler} />
              </>
              :
              <div>
                <div className="attachedfile">
                  {selectedFile.name}
                  <label className="label2" htmlFor="file"><FiEdit /></label>
                  <input id="file" type="file" accept=".pdf" name="file" style={{ visibility: "hidden" }} onChange={fileHandler} />
                </div>
              </div>
            }
            </div>
            } 
            {!isValidFile && <p className="error">Campo obrigatório</p>}

            <div>
              <p className="title">Essa missão é relacionada a um empreendimento?</p>
              <FormControl required>
                <RadioGroup>
                  <FormControlLabel value="yes" control={<Radio value='sim' onChange={handleChangeBuilding} checked={hasBuilding === 'sim'} />} label="Sim" />
                  <FormControlLabel value="no" control={<Radio value='nao' onChange={handleChangeBuilding} checked={hasBuilding === 'nao'} />} label="Não (A missão será mostrado em Missões Gerais)" />
                </RadioGroup>
              </FormControl>
            </div>
            {!isValidRelated && <p className="error">Campo obrigatório</p>}

            {hasBuilding === 'sim' &&
              <div>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">{isEdit ? selectedEnterprise.enterprise_name : 'Selecione o empreendimento'}</InputLabel>
                  <Select
                  
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedEnterprise.name_enterprise}
                    onChange={handleSelectedEnterprise}
                    label="Selecione o empreendimento"
                    fullWidth
                  >
                    {enterprises.map(enterprise => (
                      <MenuItem key={enterprise.cod_enterprise} value={enterprise.cod_enterprise}>{enterprise.name_enterprise}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            }

            <div>
              <p className="title">Qual o nível de acesso dessa missão?</p>
              <FormControl required>
                <RadioGroup>
                  <FormControlLabel value="Publico" control={<Radio value='publico' onChange={handleChangePublic}  checked={isPublic === 'publico'}/>} label="Público - Poderá ser enviado para clientes via mensagem" />
                  <FormControlLabel value="Privado" control={<Radio value='privado' onChange={handleChangePublic} checked={isPublic === 'privado'} />} label="Privado - Apenas membros da equipe HM podem visualizar" />
                </RadioGroup>
              </FormControl>
            </div>
            {!isValidOption && <p className="error">Campo obrigatório</p>}

          </div>
          <div className="sidebar-form">
            <div className="options-side">
            
            {!isValidActive && <p className="error">Campo obrigatório</p>}
              <div style={{marginBottom: '44px'}}>
                <p className="title">Tipo de Missão</p>
                <FormGroup row>
                  <RadioGroup>
                    {categories.map((c) => 
                      <FormControlLabel  value={c.category_name} control={<Radio value={c.id} onChange={handleChangeType} checked={selectedCategory[0] === c.id} />} label={c.category_name} />

                    )}
                    {/* <FormControlLabel value="Missao de vendas" control={<Radio value='Vendas' onChange={handleChangeType} checked={selectedCategory === 'Vendas'} />} label="Missão de vendas" />
                    <FormControlLabel value="Missao de visitas" control={<Radio value='Visitas' onChange={handleChangeType} checked={selectedCategory === 'Visitas'} />} label="Missão de visitas" /> */}
                  </RadioGroup>
                </FormGroup>
              </div>
            </div>
            {isEdit && 
              <div>
                <p className="title">Antecipar encerramento da missão</p>
                <FormControlLabel
                    control={
                      <Checkbox
                        onClick={() => setDisabled(!disabled)}
                        name="disabled"
                      />
                    }
                    label='Encerrar imediatamente'
                  />
              </div>
            }
          </div>
        </FormControl>
      </div>
      <div className="buttons">
        {!isEdit2 ?
        <>
          <ButtonAlt $modal onClick={(event) => onSubmitForm(event, "saveDraft")} disabled={disabledButtons}>Salvar como rascunho</ButtonAlt>
          <ButtonAlt $primary onClick={(event) => onSubmitForm(event, "publish")} disabled={disabledButtons}>Salvar e Publicar</ButtonAlt>
        </>
        :
        <>
          <ButtonAlt $primary onClick={(event) => onSubmitForm(event, "saveEdit")} disabled={disabledButtons}>Salvar Alteraçoes</ButtonAlt>
          {selectedData.is_draft && 
            <ButtonAlt $primary onClick={(event) => onSubmitForm(event, "publishEdit")} disabled={disabledButtons}>Salvar e Publicar</ButtonAlt>
          }
          <ButtonAlt $modal onClick={handleOpen}>Arquivar</ButtonAlt>
        </>
        }
        
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {!isDelete ?
            <div className="modalcontent">
              <IconWrapper>
                <MdOutlineDescription />
              </IconWrapper>
              <BoldP>Tem certeza de que deseja arquivar essa missão?</BoldP>
              <ButtonGroup>
                <ButtonArticle $primary $modal onClick={handleDelete}>Arquivar</ButtonArticle>
                <ButtonArticle $modal onClick={handleClose}>Cancelar</ButtonArticle>
              </ButtonGroup>
            </div>
            :
            <div>
              <IconWrapper>
                <MdOutlineDescription />
              </IconWrapper>
              <BoldP>A missão foi arquivada com sucesso.</BoldP>
              <ButtonArticle $confirmation onClick={handleClose}>Voltar</ButtonArticle>
            </div>
          }

        </Box>
      </Modal>
    </Container>
  )
}

export default Mission;