import styled from "styled-components";

export const BannerWrapper = styled.div `
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
height:100%;
background: #EAEAEA;
 p{
    font-size:12px;
    font-weight: 700;
    color: #666666;
    line-break: anywhere;
    text-align: center;
    padding: 0 20px;
 }
`