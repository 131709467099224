import styled from "styled-components";

interface Props{
  open: boolean;
}

export const Container = styled.div<Props>`
  position: absolute;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  padding: 2rem;
  top: 0;
  left: 0;

  background-color: rgba(100,100,100,0.4);

  display: flex;
  align-items: center;
  justify-content: center;

  ${
    props => !props.open && `
      display: none;
      width: 0;
      height: 0;
      backgroun: transparent;
      * {
        display: none;
      }
    `
  }
`;

export const Body = styled.div`
  max-width: 90%;
  max-height: 95%;
  min-width: 300px;
  min-height: 300px;
  width: 50%;

  display: flex;
  flex-direction: column;

  background-color: #fff;
  border-radius: 0.36rem;
  overflow-y: auto;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  border-bottom: 1px solid rgba(200,200,200,0.5);
`;

export const Title = styled.div`
  width: 100%;
  color: var(--blue);
`;

export const Content = styled.div`
  width: 100%;
  flex: 1;
  padding: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Close = styled.button`
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 0;
  cursor: pointer;

  svg{
    font-size: 1.5rem;
  }
`;