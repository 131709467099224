import api from "../services/api"
import {FileProps, FileResponse} from '../utils/types/RealtorTypes'

const useEditArchive = async (params: FileProps): Promise<FileResponse> => {
    try{
        const formData = formatFormData(params);

        const res = await api.put('/realtor/file', formData, 
        {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }

        );
        return res.data;
    } catch {
        return {} as FileResponse
    }
}
const formatFormData = (params: FileProps) => {
    const formData = new FormData();
    Object.entries(params).forEach(param => {
      const [ key, value ] = param;
      if (typeof value !== 'undefined' && value !== null) {
        if (Array.isArray(value)) {
          value.forEach(item => formData.append(key, String(item)));
        } else {
          formData.append(key, value);
        }
      }
    })

    return formData;
}
export default useEditArchive;