import styled from "styled-components";

export const Input = styled.input`
  width: 90%;
  padding: 0.2rem;
  border-radius: 2rem;
  border: 2px solid var(--blue-800);
  font-size: 1rem;
  padding: 0.5rem;
  outline: none;
  background: transparent;
  color: #fff;
  

  &:valid, &:-webkit-autofill, &:-webkit-autofill:focus{
    background: transparent!important;
  }
`;