import { ImSpinner, ImSpinner2 } from "react-icons/im";
import { Container } from "./style";

interface Props{
    type?: "primary" | "secondary";
}

const Spinner = ({ type, ...rest }: Props) => {
    return (
        <Container>
            {(type && type === "secondary") ? <ImSpinner2 {...rest}  /> : <ImSpinner {...rest} />}
        </Container>
    )
}

export default Spinner;