/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Container } from "./style";
import { formatDataToCSVAnalytic, formatDataToCSVDotz } from "../../../utils/exportsCSV";
import { format } from "date-fns";
import { ResponseDotzReport } from "../../../interfaces/ResponseReport";
import Table from "../../Table";
import { calcValuesDotz } from "../../../utils/calcValuesDotz";

interface Props {
    dataReport: ResponseDotzReport[];
    isLoading: boolean;
}

const columns = [
    { title: "CPF", attribute_name_tbody: "document" },
    { title: "Pontos", attribute_name_tbody: "bonus_points" },
    { title: "Número da venda", attribute_name_tbody: "sale_number" },
    { title: "Depositado", attribute_name_tbody: "was_deposited" },
    { title: "Bloqueado", attribute_name_tbody: "is_blocked" },
    { title: "Cod. Empreendimento", attribute_name_tbody: "enterprise_code" },
    { title: "Empreendimento", attribute_name_tbody: "enterprise_name" },
    { title: "Valor da venda", attribute_name_tbody: "sale_value" },
    { title: "Data da venda", attribute_name_tbody: "sale_date" },
    { title: "Data criação", attribute_name_tbody: "created_at" },
    { title: "Data depósito", attribute_name_tbody: "deposited_at" }
];

const TableDotz = ({ dataReport, isLoading }: Props) => {
    const [dataCSV, setDataCSV] = useState<any[]>([]);

    useEffect(() => {
        setDataCSV(formatDataToCSVDotz(dataReport));
    }, [dataReport])
    return (
        <Container>
            <div className="table">
                <div>
                    <h2>Depósitos dotz</h2>
                </div>
                <div className="data-export">
                    <CSVLink filename={"Depósitos " + format(new Date(), "dd-MM-yyyy-HH-mm")} data={dataCSV}>Exportar CSV</CSVLink>
                </div>
            </div>
            <div className="content">
                <Table 
                    columns={columns} 
                    data={dataReport} 
                    isLoading={isLoading} 
                    offset={15} 
                    nowrapCell={["cpf"]} 
                />
            </div>
        </Container>
    )
}

export default TableDotz;