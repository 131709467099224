import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: 100%;

  padding: 0.5rem;

  > h2{
    padding: 1rem;
  }
  
  .title{
    padding: 1rem;
    color: var(--blue);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .user-data{
    padding: 1rem;

    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0.24rem;

    max-width: 50%;
    box-shadow: 0 3px 10px 0px rgba(29,25,57,0.05);

    .button{
      display: flex;
      flex-direction: row;
      justify-content: center;

      button{
        background-image: var(--button-secondary);
        svg{
          font-size: 1.2rem;
        }
      }
    }

    .title-card{
      display: flex;
      justify-content: space-between;
      align-items: center;      
    }

    .columns{
      padding: 0.5rem;
      border-radius: 0.36rem;      

      .rows{
        margin-top: 0.36rem;
        margin-bottom: 0.36rem;
      }
    }
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;

  form{
    display: flex;
    flex-direction: column;
    
    padding: 1rem;

    label{
      font-size: 0.8rem;
      margin-top:0.5rem
    }

    input{
      width: 100%;
      border: 1px solid var(--blue);
      outline: none;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin: 0.5rem 0;
      font-size: 1rem;
    }

    > div.grouped{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.5rem;

      .labels{
        display: flex;
        flex: 1;
        flex-direction: column;
      }
    }

    button{
      max-width: 180px;
      margin-left: auto;
      margin-right: auto;
      background-image: var(--button-secondary);
    }
  }

  @media (max-width: 820px) {
    form{
    }
  }
`;