import { ResponseDotzReport, ResponseReferFriendsReport, ResponseReport } from "../../interfaces/ResponseReport";
import { calcValuesDotz } from "../calcValuesDotz";

export const formatDataToCSVAnalytic = (data: ResponseReport[]) => {
  const values = calcValuesDotz(data);
  return values.map((rp) => {
    return {
      CPF: rp.cpf,
      NOME: rp.name,
      COD_COLIGADA: rp.cod_coligada,
      COLIGADA: rp.name_coligada,
      EMPREENDIMENTO: rp.enterprise,
      COD_DEPARTAMENTO: rp.cod_department,
      DEPARTAMENTO: rp.name_department,
      DATA: rp.date,
      DESCONTO_DOTZ: rp.discount_dotz,
      RECEITA_DOTZ: rp.income_dotz,
      EXTRATO: rp.total,
    }
  })
}
export const formatDataToCSVDotz = (data: ResponseDotzReport[]) => {
  return data.map((rp) => {
    return {
      CPF: rp.document,
      NUMERO_VENDA: rp.sale_number,
      COD_EMPREENDIMENTO: rp.enterprise_code,
      EMPREENDIMENTO: rp.enterprise_name,
      PONTOS: rp.bonus_points,
      FOI_DEPOSITADO: rp.was_deposited,
      BLOQUEADO: rp.is_blocked,
      VALOR_VENDA: rp.sale_value,
      DATA_VENDA: rp.sale_date,
      DATA_CRIACAO: rp.created_at,
      DATA_DEPOSITO: rp.deposited_at
    }
  })
}
export const formatDataToCSVSynthetic = (data: ResponseReport[]) => {
  const values = calcValuesDotz(data);
  return values.map((rp, i) => {
    return {
      COD_COLIGADA: rp.cod_coligada,
      COLIGADA: rp.name_coligada,
      EMPREENDIMENTO: rp.enterprise,
      COD_DEPARTAMENTO: rp.cod_department,
      DEPARTAMENTO: rp.name_department,
      DESCONTO_DOTZ: rp.discount_dotz,
      RECEITA_DOTZ: rp.income_dotz,
      EXTRATO: rp.total,
    }
  })
}

export const formatDataToCSVReferFriend = (data: ResponseReferFriendsReport[]) => {
  return data.map((rp) => {
    return {
      NOME_INDICADO: rp.indication_name,
      EMPREENDIMENTO_INDICADO: rp.indication_enterprise_name,
      BLOCO_EMPREENDIMENTO_INDICADO: rp.indication_enterprise_block,
      UNIDADE_INDICADO: rp.indication_enterprise_unit,
      DATA_ASS_CONTRATO_INDICADO: rp.indication_contract_signing_date,
      DATA_VENDA_INDICADO: rp.indication_sale_date,
      NUM_VENDA_INDICADO: rp.indication_sale_number,
      CPF_INDICADO: rp.indication_document,
      EMAIL_INDICADO: rp.indication_email,
      NUMERO_INDICADO: rp.indication_phone_number,
      COD_EMPREENDIMENTO_INDICADO: rp.indication_enterprise_code,
      EMAIL_IMOBILIARIA_INDICADO: rp.indication_agency_email,
      IMOBILIARIA_INDICADO: rp.indication_agency_name,

      NOME_INDICADOR: rp.indicator_name,
      CPF_INDICADOR: rp.indicator_document,
      NUM_VENDA_INDICADOR: rp.indicator_sale_number,
      EMPREENDIMENTO_INDICADOR: rp.indicator_enterprise_name,
      BLOCO_EMPREENDIMENTO_INDICADOR: rp.indicator_enterprise_block,
      UNIDADE_INDICADOR: rp.indicator_enterprise_unit,
      DATA_ASS_CONTRATO_INDICADOR: rp.indicator_contract_signing_date,
      DATA_VENDA_INDICADOR: rp.indicator_sale_date,
      IMOBILIARIA_INDICADOR: rp.indicator_agency_name,
      EMAIL_IMOBILIARIA_INDICADOR: rp.indicator_agency_email,
      COD_EMPREENDIMENTO_INDICADOR: rp.indicator_enterprise_code,

      LINK_COMPARTILHADO: rp.shared_link,
      CONTA: rp.account_name,
      NUMERO_CONTA: rp.account_number,
      AGENCIA: rp.bank_branch,
      CODIGO_BANCO: rp.bank_code,
      NOME_BANCO: rp.bank_name,
      CHAVE_PIX: rp.pix_key,
      TIPO_CHAVE_PIX: rp.type_pix_key,
      STATUS: rp.status,
      PAGO: rp.paid,

      DATA_INDICACAO: rp.indication_date,

    }
  })
}
