import { ResponseReport } from "../../interfaces/ResponseReport";

export const calcValuesDotz = (data: ResponseReport[]) => {
  return data.map((rp) => {
    const dotz = Number(rp.discount_dotz) * 100;

    const total = dotz * 0.012;
    const discount = Number(rp.discount_dotz);
    const income = total - discount;
    
    return {
      ...rp,
      discount_dotz: discount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      income_dotz: income.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      total: total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
  })
}