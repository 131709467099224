import styled from "styled-components";

export const Container = styled.div`
  svg{
    animation: spinner 2s linear infinite;;
  }

  @keyframes spinner {
    100%{
      transform: rotate(360deg);
    }
  }
`;