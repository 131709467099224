import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M12.45 8.16l-1.065-1.065-3.18 3.188-1.59-1.598L5.55 9.75l2.655 2.655L12.45 8.16z"
        fill="#fff"
      />
      <path
        d="M14.25 3H3.75a1.5 1.5 0 00-1.5 1.5v9a1.5 1.5 0 001.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5v-9a1.5 1.5 0 00-1.5-1.5zm0 10.5H3.75V6h10.5v7.5z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent
