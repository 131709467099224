import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  
  div.content{
    width: 100%;
    overflow-x: auto;
  }
  
  div.table{
    box-shadow: 0 -3px 10px 0px rgba(29,25,57,0.03);
    border-radius: 0.48rem 0.48rem 0 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 1rem;
    
    div.data-export{
      padding: 0.5rem 1rem;
      background-color: var(--green);
      border-radius: 0.24rem;

      a{
        color: #fff;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
`;