import MUIRichTextEditor from 'mui-rte';
import { convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { MuiThemeProvider, createTheme } from '@material-ui/core'
import { Global } from './styles';
import { useDashboard } from '../../context/DashboardContext';


interface Props {
    onChange: (value: string) => void;
    label: string;
    id?: string;
};

interface IPosition {
    start: number;
    end: number;
}

const theme = createTheme();

Object.assign(theme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
                width: "100%",
                borderRadius: 15,
                background: '#F2F2F2',
                paddingBottom: 24,
                border: '1px solid #cecece',
            },
            editor: {
                padding: '8px 16px',
            },
            placeHolder: {
                padding: '0 12px 16px 12px',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }
        }
    }
})

export const TextArea = ({ label, onChange, id }: Props) => {
    const { selectedData, isEdit } = useDashboard()

    const handleChange = (event: Draft.DraftModel.ImmutableData.EditorState) => {
        const content = convertToRaw(event.getCurrentContent());

        const count = Object.values(content.entityMap).length;

        for (let index = 0; index < count; index++) {
            if (content.entityMap[index].type === 'LINK') {
                const url = content.entityMap[index].data.url;
                content.entityMap[index].data.target = '_blank'

                if (
                    (url && url.length <= 5) ||
                    (url && url.length > 5 && url.substr(0,4).toLowerCase() !== 'http')
                ) {
                    content.entityMap[index].data.url = 'https://' + url;
                }
            }
        }

        content && onChange(JSON.stringify(content));
    }
    return (
        <Global>
            <MuiThemeProvider theme={theme}>
                <MUIRichTextEditor
                    controls={[
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        'undo',
                        'redo',
                        'link',
                        'numberList',
                        'bulletList',
                    ]}
                    defaultValue={isEdit ? selectedData.description : ''}
                    label={label}
                    id={id}
                    onChange={handleChange}
                    draftEditorProps={{}}
                />
            </MuiThemeProvider>
        </Global>
    )
}

export const TextToHtml = (value: string) => {
    try {
        const content = JSON.parse(value);
        return <p
            dangerouslySetInnerHTML={{
                __html: stateToHTML(convertFromRaw(content))
            }} 
        />;
    } catch {
        return value;
    };
}

export const SliceText = (value: string, position: IPosition) => {
    let content;
    try {
        content = JSON.parse(value);

        const data = content?.blocks;

        if (data && Array.isArray(data)) {
            return data[0].text?.slice(position.start, position.end) ?? '';
        }

        return '';
    } catch {
        return value.slice(position.start, position.end);
    };
}