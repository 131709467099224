import { useState } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { createContext, ReactNode, useCallback, useContext } from "react"
import { toast } from "react-toastify";
import { ResponseDotzReport, ResponseReferFriendsReport, ResponseReport } from "../interfaces/ResponseReport";
import { PermisionResponse, ResponseUsers } from "../interfaces/ResponseUsers";
import api from "../services/api";
import { ReturnApi } from "../interfaces/ReturnApi";
import { CategoriesProps, DataItem2, Enterprise } from "../utils/types/RealtorTypes";
interface DashboardContextProps {
    totalDotzExchangeValue: number;
    totalDotzIncomeValue: number;
    totalDotzValue: number;
    isGeneral: boolean;
    typeList: string;
    isEdit: boolean;
    breadCrumbsName: string;
    selectedData: DataItem2;
    setTypeList: (typeList: string) => void;
    setIsEdit: (isEdit: boolean) => void;
    setSelectedData: (selectedData: DataItem2) => void;
    setBreadCrumbsName: (breadCrumbsName: string) => void;
    setIsGeneral: (isGeneral: boolean) => void;
    getUserData: () => ResponseUsers | undefined;
    catchFunctions: (a: any) => any;
    signOut: () => void;
    getToken: () => string | null;
    fetchReportDotz: (a: string, b: string) => Promise<ResponseReport[]>
    fetchReportDotzDeposit: (a: string, b: string, c?: string, d?: number) => Promise<ResponseDotzReport[]>
    requestReferFriendReport: (initial_date: string, final_date: string) => Promise<ResponseReferFriendsReport[]>
    fetchUsersList: () => Promise<ResponseUsers[]>
    fetchEnterprises: () => Promise<Enterprise[]>
    fetchCategories: (type: any) => Promise<CategoriesProps[]>
}

interface DashboardContextProviderProps {
    children: ReactNode;
}

const DashboardContext = createContext({} as DashboardContextProps)

export const DashboardContextProvider = ({
    children
}: DashboardContextProviderProps) => {
    const [totalDotzExchangeValue, setTotalDotzExchangeValue] = useState(0);
    const [totalDotzIncomeValue, setTotalDotzIncomeValue] = useState(0);
    const [totalDotzValue, setTotalDotzValue] = useState(0);
    const [isGeneral, setIsGeneral] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [typeList, setTypeList] = useState<string>('')
    const [breadCrumbsName, setBreadCrumbsName] = useState<string>('');
	const [selectedData, setSelectedData] = useState({} as DataItem2);

    const getToken = useCallback(() => {
        return sessionStorage.getItem('token');
    }, []);

    const getUserData = useCallback((): ResponseUsers => {
        const data = sessionStorage.getItem('user-data');
        return data ? JSON.parse(data) : undefined;
    }, []);

    const signOut = useCallback(() => {
        sessionStorage.removeItem('token');
        window.location.replace('/login');
    }, []);

    const catchFunctions = (error: any) => {
        
        if (
            error?.response &&
            (error.response.status === 401 || error.response.status === 403)
        ) {
            toast.error('Sessão expirada');
            signOut();
        }
        return;
    }
    const fetchPermision = async (): Promise<PermisionResponse[]> => {
        try {
            const { data } = await api.get(`/permissions` , {
                headers: {
                    "Authorization": `Bearer ${getToken()}`
                }
            })
            return data
        }   catch (error: any) {
            catchFunctions(error);
            return []
        }
    }
    const fetchUsersList = async (): Promise<ResponseUsers[]> => {
        const request = await requestUsersList();

        if (request.status === "error") {
            toast.error(request.message);
            return [];
        }

        return request.data;
    }

    const requestUsersList = async (): Promise<ReturnApi<ResponseUsers[]>> => {
        
        try {
            const { data } = await api.get(`/user/list`, {
                headers: {
                    "Authorization": `Bearer ${getToken()}`
                }
            });

            return{
                status: "success",
                message: "",
                data
            }
        } catch (error: any) {
            catchFunctions(error);

            return{
                status: "error",
                message: "Error ao listar",
                data: []
            }
        }
    }

    const requestDataReport = async (initial_date: string, final_date: string): Promise<ReturnApi<ResponseReport[]>> => {
        
        try {
            const { data } = await api.get(`/report?initial_date=${initial_date}&final_date=${final_date}`, {
                headers: {
                    "Authorization": `Bearer ${getToken()}`
                }
            });

            return{
                status: "success",
                message: "",
                data
            }
        } catch (error: any) {
            catchFunctions(error);

            return{
                status: "error",
                message: "Error ao listar",
                data: [],
            }
        }
    }
    const fetchEnterprises = async (): Promise<Enterprise[]> => {
        try{
            const { data } = await api.get(`/realtor/enterprises`, {
                headers: {
                    "Authorization": `Bearer ${getToken()}`
                }
            });

            return data 
        } catch (error: any){
            catchFunctions(error);

            return []
        
        }
    }
    const fetchCategories = async (type: any): Promise<CategoriesProps[]> => {
        try{
            const { data } = await api.get(`/realtor/categories/`,{
                headers: {
                    "Authorization": `Bearer ${getToken()}`
                },
                params: type
            });

            return data 
        } catch (error: any){
            catchFunctions(error);

            return error
        
        }
    }
    
    const fetchReportDotz = async (initial_date: string, final_date: string): Promise<ResponseReport[]> => {
        initial_date = format(new Date(initial_date), "yyyy-MM-dd'T'HH:mm:ss");
        final_date = format(new Date(final_date), "yyyy-MM-dd'T'HH:mm:ss");
        
        const request = await requestDataReport(initial_date, final_date);

        if (request.status === "error") {
            toast.error(request.message);
            return [];
        }

        const report = request.data;

        let dotzExchangeValue = 0;
        let dotzIncomeValue = 0;
        let dotzTotalValue = 0;

        report.forEach(rp => {
            const dotz = (Number(rp.discount_dotz) * 100);
            dotzExchangeValue = dotzExchangeValue + dotz;
            dotzIncomeValue = dotzIncomeValue + (dotz * 0.002);
            dotzTotalValue = dotzTotalValue + (dotz * 0.012);
        });

        setTotalDotzExchangeValue(dotzExchangeValue);
        setTotalDotzIncomeValue(dotzIncomeValue);
        setTotalDotzValue(dotzTotalValue);

        return report;   
    }

    const requestDataReportDotz = async (start_date: string, end_date: string, was_deposited?: string, enterprise_code?: number): Promise<ReturnApi<ResponseDotzReport[]>> => {
        try {
            const { data } = await api.get(`/report/deposits` ,
                {
                  headers: {
                    "Authorization": `Bearer ${getToken()}`
                },
                params: {
                    start_date: start_date,
                    end_date: end_date,
                    was_deposited:!was_deposited || was_deposited === "Todos" ? undefined : was_deposited,
                    enterprise_code: enterprise_code
                }
            });

            return{
                status: "success",
                message: "",
                data
            }
        } catch (error: any) {
            catchFunctions(error);

            return{
                status: "error",
                message: "Error ao listar",
                data: [],
            }
        }
    }

    const fetchReportDotzDeposit = async (start_date: string, end_date: string, was_deposited?: string, enterprise_code?: number): Promise<ResponseDotzReport[]> => {
        start_date = format(new Date(start_date), "yyyy-MM-dd'T'HH:mm:ss");
        end_date = format(new Date(end_date), "yyyy-MM-dd'T'HH:mm:ss");
        
        const request = await requestDataReportDotz(start_date, end_date, was_deposited, enterprise_code);

        if (request.status === "error") {
            toast.error(request.message);
            return [];
        }

        return request.data.map(item => ({
            ...item,
            was_deposited: item.was_deposited ? "sim" : "não",
            is_blocked: item.is_blocked ? "sim" : "não",
            sale_value: item.sale_value?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
            sale_date: new Date(item.sale_date).toLocaleDateString("pt-BR"),
            created_at: new Date(item.created_at).toLocaleDateString("pt-BR"),
            updated_at: new Date(item.updated_at).toLocaleDateString("pt-BR"),
            deposited_at: item.deposited_at !== null ? new Date(item.deposited_at).toLocaleDateString("pt-BR") : '',
        }));
    }
    const requestReferFriendReport = async (initial_date: string, final_date: string): Promise<ResponseReferFriendsReport[]> => {
        initial_date = format(startOfDay(new Date(initial_date)), "yyyy-MM-dd'T'HH:mm:ss");
        final_date = format(endOfDay(new Date(final_date)), "yyyy-MM-dd'T'HH:mm:ss");
        try {
            const { data } = await api.get<ResponseReferFriendsReport[]>(`/indique-um-amigo/report?initial_date=${initial_date}&final_date=${final_date}`, {
                headers: {
                    "Authorization": `Bearer ${getToken()}`
                }
            });

            return data
        } catch (error: any) {
            catchFunctions(error);

            return [] as ResponseReferFriendsReport[]
        }
    }

    return (
        <DashboardContext.Provider
            value={{
                signOut,
                getToken,
                fetchReportDotz,
                fetchReportDotzDeposit,
                fetchUsersList,
                fetchEnterprises,
                fetchCategories,
                totalDotzExchangeValue,
                totalDotzIncomeValue,
                totalDotzValue,
                catchFunctions,
                getUserData,
                isGeneral,
                setIsGeneral,
                breadCrumbsName,
                setBreadCrumbsName,
                selectedData,
                setSelectedData,
                isEdit,
                setIsEdit,
                typeList,
                setTypeList,
                requestReferFriendReport
            }}
        >
            {children}
        </DashboardContext.Provider>
    )
}

export const useDashboard = () => {
    return useContext(DashboardContext);
}