import styled from "styled-components";

export const Container = styled.div``;

export const TableContainer = styled.table`
  box-shadow: 0 3px 10px 0px rgba(29,25,57,0.05);
  width: 100%;
  border-top: 1px solid rgba(29,25,57,0.1);
  border-spacing: 0;
  min-width: 968px;
  background-color: #fff;
  padding: 1rem;
  
  thead{
    th{
      font-size: 0.875rem;
      padding: 0.32rem 0.24rem;
      text-align: left;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      white-space: nowrap;
      color: #1D1939;
      margin-bottom: 0.75rem;
      min-width: 200px;
      
    }
  }

  tbody{
    position: relative;

    .bg{
      background-color: rgba(160, 164, 177, 0.10)
    }

    td{
      text-align: start;
      font-family: Montserrat;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.25rem;
      padding-right: 10px ;
    }

    tr.loading{
      height: 2rem;
    }

    tr.loading td{
      z-index: 0;
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
    }
  }
`;

export const Pagination = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    .previous {
      background: url('../../../../public/images/left-pagination.svg') no-repeat;
    }

    .next {
      background: url('../../../../public/images/right-pagination.svg') no-repeat;
    }

    .next,
    .previous {
      background-position: center 1rem;
      padding: 1rem;

      a {
        display: block;
        height: 2rem;
        width: 2rem;
      }
    }

    li {
      width: 2rem;
      height: 2rem;
      padding: 0.5rem;
      text-align: center;
      margin-right: 1rem;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        background: #D60B52;
        border-radius: 50%;

        a{
          color: #ffffff;
          font-weight: 700;
        }
      }

      a {
        color: #1D1939;
        font-family: Montserrat;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem
      }
    }
  }

  @media (max-width: 1360px) {
    margin-top: 0.5rem;

    .pagination {
      .next,
      .previous {
        background-position: center 0.7rem;
        padding: 0.7rem;

        a {
          height: 2rem;
          width: 2em;
        }
      }

      li {
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
        text-align: center;
        margin-right: 0.7rem;
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 1024px) {
    margin-top: 0.5rem;

    .pagination {
      .next,
      .previous {
        background-position: center 1rem;
        padding: 1rem;

        a {
          height: 2rem;
          width: 2em;
        }
      }

      li {
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
        text-align: center;
        margin-right: 0.7rem;
        font-size: 1.2rem;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 0.5rem;

    .pagination {
      .next,
      .previous {
        background-position: center 0.7rem;
        padding: 0.7rem;

        a {
          height: 2rem;
          width: 2em;
        }
      }

      li {
        width: 2rem;
        height: 2rem;
        padding: 0.3rem;
        text-align: center;
        margin-right: 0.7rem;
        font-size: 1rem;
      }
    }
  }
`;