import { Button as ButtonStyle } from './style'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> { }

const Button = ({ children, ...rest }: Props) => {
    return (
        <ButtonStyle {...rest}>
            {children}
        </ButtonStyle>
    )
}

export default Button;