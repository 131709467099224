import * as React from "react"

const SvgComponent = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      {...props}
    >
      <path
        d="M26 53.063c-.688 0-1.25-.591-1.25-1.313v-31.5c0-.722.563-1.313 1.25-1.313h12.5V25.5c0 1.452 1.117 2.625 2.5 2.625h6.25V51.75c0 .722-.563 1.313-1.25 1.313H26zM26 15c-2.758 0-5 2.354-5 5.25v31.5c0 2.896 2.242 5.25 5 5.25h20c2.758 0 5-2.354 5-5.25V27.674c0-1.395-.523-2.732-1.46-3.716l-7.08-7.424A4.866 4.866 0 0038.93 15H26zm7.5 21c0-.696-.263-1.364-.732-1.856A2.442 2.442 0 0031 33.375c-.663 0-1.299.277-1.768.769A2.693 2.693 0 0028.5 36c0 .696.263 1.364.732 1.856a2.442 2.442 0 001.768.769c.663 0 1.299-.277 1.768-.769.469-.492.732-1.16.732-1.856zm5.406 3.847a1.24 1.24 0 00-1.031-.566 1.24 1.24 0 00-1.031.566l-3.227 4.898-.93-1.567a1.236 1.236 0 00-1.062-.615c-.43 0-.828.23-1.063.615l-3.125 5.25c-.242.402-.25.91-.03 1.329.218.418.64.672 1.093.672h15c.469 0 .89-.27 1.11-.705a1.353 1.353 0 00-.079-1.354l-5.625-8.53v.007z"
        fill="#666666"
      />
    </svg>
  )
}

export default SvgComponent
