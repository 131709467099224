import styled from "styled-components";

export const Container = styled.aside`
  width: inherit;
  height: inherit;
  position: inherit;
  background-color: #fff;

  display: flex;
  flex-direction: column;

  .brand{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    height: 10vh;
    max-height: 90px;

    img{
      width: 30%;
    }

    span{
      margin-left: 0.3rem;
      color: var(--magenta);
      font-size: 1.5rem;
      font-weight: bold;

      &:last-child{
        color: var(--blue);
      }
    }
  }

  .menu{
    padding: 1rem;
    flex: 1;
    display: flex;
    gap: 1rem;
    flex-direction: column;

    > div{
      padding-top: 1rem;

      > label{
        font-size: 0.8rem;
        margin-left: 0.8rem;
        font-weight: bold;
        background-color: #fff;
        padding: 0 0.5rem;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg{
          margin-right: 0.5rem;
          font-size: 1rem;
        }
      }
      
      ul{
        padding-top: 0.8rem;
        width: 100%;

        li{
          display: flex;
          height: 3rem;
          position: relative;

          &.is-active{
            border: 2px solid var(--blue-800);
            background-color: transparent;
            border-radius: 1rem;
            background-color: #4d466b25;
          }

          span{
            margin-left: auto;
            display: flex;
            padding: 0.5rem;
            align-items: center;
            width: 90%;
            background-color: transparent;
            
            a{
              color: var(--blue);
              text-decoration: none;
              font-size: 1rem;

              &.is-active{                
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  .footer{
    flex: 0 0 15%;

    .button-show-sidebar{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      button{
        border: none;
        background-color: transparent;
        cursor: pointer;
        border: solid 2px var(--blue);
        border-radius: 50%;
        padding: 0.8rem;
        font-size: 0;

        svg{          
          color: var(--blue);
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }

    @media (min-width: 821px) {
      display: none;
      visibility: hidden;
    }

    @media (min-width: 425px) {
      .button-show-sidebar{
        align-items: center;
      }
    }
  }
`;