import styled from "styled-components";

export const Container = styled.div`
  .table-users{
    thead{      
      th{
        color: var(--blue-800);
        font-weight: bold;
      }
    }

    tbody{
      button{
        border: none;
        background-color: transparent;
        cursor: pointer;

        &:disabled{
          cursor: not-allowed;
        }
      }
    }
  }
`;

export const ContentModal = styled.div`
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3{
    margin: 1rem 0;
    font-weight: 400;
  }

  form{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 300px;

    label{
      font-size: 0.8rem;
      margin-top:0.5rem
    }

    input{
      width: 100%;
      border: 1px solid var(--blue);
      outline: none;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin: 0.5rem 0;
      font-size: 1.5rem
    }

    button{
      margin-left: auto;
      margin-right: auto;
      max-width: 180px;
      background-image: var(--button-secondary);
    }
  }
`;