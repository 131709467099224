import { Link, useLocation } from "react-router-dom";
import { Container } from "./style";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { FiUsers } from 'react-icons/fi';
import { BsChevronLeft } from 'react-icons/bs';
import { useDashboard } from "../../context/DashboardContext";

const listItemsDashboard = [
    {
        title: 'Descontos Dotz',
        href: '/'
    },
    {
        title: 'Depósitos Dotz',
        href: '/dotz-deposits'
    }
];

const listItensMarketing = [    
    {
        title: 'Marketing',
        href: '/marketing/'
    },
    {
        title: 'Indique um amigo',
        href: '/refer-friends'
    },
];

const listItemsUsers = [    
    {
        title: 'Atualizar dados',
        href: '/user/update'
    },
    {
        title: 'Gerenciar',
        href: '/user/manage'
    },
];

interface Props{
    setIsShowSidebar: () => void;
}

const Aside = ({ setIsShowSidebar }: Props) => {
    const { pathname } = useLocation();
    const { getUserData } = useDashboard();

    const user = getUserData();

    return (
        <Container>
            <div className="brand">
                <img src="/images/hm-estelar-element.png" alt="" />
                <span>hm</span>
                <span>estelar</span>
            </div>
            <div className="menu">
                {/* change verification of permission to permission_level  <=2 */}
                {user && (user.permission === 'admin' || user.permission === 'finance')  && 
                <div>
                    <label><MdOutlineSpaceDashboard /> DASHBOARD</label>
                    <ul>
                        {
                            listItemsDashboard.map(item => (
                                <li key={item.title} className={item.href === pathname ? 'is-active' : ''}>
                                    <span className={item.href === pathname ? 'is-active' : ''}>
                                        <Link className={item.href === pathname ? 'is-active' : ''} to={item.href}>{item.title}</Link>
                                    </span>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                }
                {/* change verification of permission to permission_level ==3  ||  === 2 */}
                {  user && (user.permission === 'marketing'  || user.permission === 'admin') && 
                <div>
                    <label><FiUsers /> BACKOFFICE</label>
                    <ul>
                        {listItensMarketing.map(item => (
                            <li key={item.title} className={item.href === pathname ? 'is-active' : ''}>
                                <span className={item.href === pathname ? 'is-active' : ''}>
                                    <Link className={item.href === pathname ? 'is-active' : ''} to={item.href}>{item.title}</Link>
                                </span>
                            </li> 
                        ))}
                    </ul>
                    
                </div>
                } 
                
                <div>
                    <label><FiUsers /> USUÁRIOS</label>
                    <ul>
                        {/* change verification of permission to permission_level === 1 */}
                        {
                            user && user.permission === 'admin' ?
                            listItemsUsers.map(item => (
                                <li key={item.title} className={item.href === pathname ? 'is-active' : ''}>
                                    <span className={item.href === pathname ? 'is-active' : ''}>
                                        <Link className={item.href === pathname ? 'is-active' : ''} to={item.href}>{item.title}</Link>
                                    </span>
                                </li>
                            )) :
                            listItemsUsers.filter(item => item.title !== 'Gerenciar').map(item => (
                                <li key={item.title} className={item.href === pathname ? 'is-active' : ''}>
                                    <span className={item.href === pathname ? 'is-active' : ''}>
                                        <Link className={item.href === pathname ? 'is-active' : ''} to={item.href}>{item.title}</Link>
                                    </span>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                
                {/* <div>
                    <label><MdSettings /> CONFIGURAÇÕES</label>
                    <ul>
                        {
                            listItemsSettings.map(item => (
                                <li key={item.title} className={item.href === pathname ? 'is-active' : ''}>
                                    <span className={item.href === pathname ? 'is-active' : ''}>
                                        <Link className={item.href === pathname ? 'is-active' : ''} to={item.href}>{item.title}</Link>
                                    </span>
                                </li>
                            ))
                        }
                    </ul>
                </div> */}
            </div>
            <div className="footer">
                <div className="button-show-sidebar">
                    <button onClick={setIsShowSidebar}>
                        <BsChevronLeft />
                    </button>
                </div>
            </div>
        </Container>
    )
}

export default Aside;