import styled from "styled-components";

export const Container = styled.main`
  flex: 1;
  padding: 0 1rem;
  overflow-y: auto;
`;

export const CardReports = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid rgba(29,25,57,0.1);

  .green{
    border-bottom: 5px solid var(--green);
  }

  .blue{
    border-bottom: 5px solid var(--blue-800);
  }

  .orange{
    border-bottom: 5px solid var(--orange);
  }

  .card{
    padding: 1rem;
    padding-bottom: 0.5rem;
    border-radius: 0.36rem;
    background-color: #fff;
    box-shadow: 0 3px 10px 0px rgba(29,25,57,0.05);
    width: 23%;

    display: flex;
    flex-direction: column;
    
    span{
      font-weight: bold;

      &:first-child{
        font-size: 0.9rem;
      }

      &:last-child{
        font-size: 2rem;
        text-align: center;
      }
    }
  }

  @media (max-width: 820px) {
    flex-wrap: wrap;
    .card{
      width: 100%;
      span{
        margin-top: 0.5rem;
        text-align: center;
        
        &:first-child{
          font-size: 1rem;
        }

        &:last-child{
          font-size: 1.7rem;
        }
      }
    }
  }
`;

export const FilterParams = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  > div {
    padding: 0 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    label{
      color: var(--blue);
      margin-right: 0.5rem;
      white-space: nowrap;
    }
  }

  .date-filter{
    input[type = "date"]{
      padding: 0.5rem 0.8rem;
      border: none;
      color: var(--blue);
      border-radius: 0.24rem;
    }

    div.react-datepicker-wrapper{
      display: flex;
    }

    div.react-datepicker__input-container{
      > input{
        min-height: 34px;
        padding: 0.5rem;
        border: none;
        color: var(--blue);
        border-radius: 0.24rem;
        border-radius: 1.5rem;
        background:  rgba(160, 164, 177, 0.10);
      }
    }
  }

  .coligada-filter{
    select{
      padding: 0.5rem 0.8rem;
      border: none;
      color: var(--blue);
      border-radius: 0.24rem;

      option{
        padding: 0.4rem;
      }
    }
  }

  .fetch{
    button{
      background-image: var(--button-secondary);
    }
  }

  @media (max-width: 768px) {    
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "filter_date filter_coligada" "fetch fetch";
    padding: 1rem 0;
    grid-gap: 0.3rem;

    > div {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 0;
      height: 72px;

      input, select, option{
        width: 100%;
        max-width: 12rem;
      }

      label{
        margin: 0 0 0.5rem 0;
      }
    }

    .date-filter{
      grid-area: filter_date;
    }

    .coligada-filter{
      grid-area: filter_coligada;
    }
    
    .fetch{
      grid-area: fetch;
      align-items: center;
    }
  }
`;