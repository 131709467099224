import Image from "../../../icons/Image"
import Pdf from "../../../icons/Pdf"
import Video from "../../../icons/Video"
import Ppt from "../../../icons/Ppt"
import Zip from "../../../icons/Zip"
import Doc from "../../../icons/Doc"
import Excell from "../../../icons/Excell"
import { BannerWrapper } from "./styles";

interface BannerProps{
    fileName: string | undefined;
    mimetype: string | undefined;
}

export const DefaultBanner = ({fileName, mimetype} : BannerProps) => {
    let mime = mimetype;
    let icon
    switch (mime) {
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
            icon = <Image />
            break;
        case 'application/pdf':
            icon = <Pdf />
            break;
        case 'video/mp4':
        case 'video/mpeg':
        case 'video/avi':
            icon = <Video />
            break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.ms-powerpoint':
            icon = <Ppt />
            break;
        case 'application/zip':
        case 'application/x-zip-compressed':
            icon = <Zip />
            break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/msword':
            icon = <Doc />
            break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
            icon = <Excell />
            break;
        default:
            break;
    }
    return(
        <BannerWrapper>
            {icon}
            <p>{fileName}</p>
        </BannerWrapper>
    )
}
