/* eslint-disable jsx-a11y/alt-text */
import { Table, TableCell, TableHead, TableRow, TableBody, TableContainer, Paper } from "@material-ui/core";
import { format } from "date-fns";
import { FcCancel, FcCheckmark } from "react-icons/fc";
import { BsPencilSquare } from "react-icons/bs";
import { ResponseUsers } from "../../../interfaces/ResponseUsers";
import { Container } from "./style";
import Modal from "../../Modal";
import { useState } from "react";
import { useDashboard } from "../../../context/DashboardContext";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { ContentModalUpdatePassword } from "./ContentModalUpdatePassword";
import { ReturnApi } from "../../../interfaces/ReturnApi";

interface Props{
    users: ResponseUsers[];
}

const userState = {
    id: 0,
    name: '',
    cpf: '',
    phone_number: '',
    email: '',
    available: true,
    permission: '',
    created_at: ''
}

const passwordState = { pass: '', confirm: '' }

const UsersList = ({ users }: Props) => {
    const { getToken, catchFunctions } = useDashboard();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [user, setUser] = useState<ResponseUsers>(userState);
    const [password, setPassword] = useState(passwordState);
    const [isLoading, setIsLoading] = useState(false);

    const handleChangePassword = (value: string, type: string) => {
        setPassword({
            ...password,
            [type]: value
        })
    }

    const updatePassword = async (body: any): Promise<ReturnApi<any>> => {
        try {
            const token = getToken();
            await api.put('/user/update-password/admin', body,{
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            return{
                status: "success",
                message: "Atualizado com sucesso",
                data: ""
            }
        } catch (error: any) {
            if (error.response?.data.message === "Editing user is not allowed") {
                return{
                    message: 'Não é permitido editar este usuário',
                    status: "error",
                    data: ""
                };
            }

            catchFunctions(error);
            return{
                status: "error",
                message: "Erro ao atualizar",
                data: ""
            }
        }
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (password.pass !== password.confirm) {
            toast.error('Senhas divergentes');
            return;
        }

        setIsLoading(true);

        const updated = await updatePassword({
            id: user.id,
            new_password: password.pass
        });
        
        setIsLoading(false);

        if (updated.status === "success") {
            toast.success(updated.message);

            setUser(userState);
            setPassword(passwordState);
            setIsOpenModal(false);

            return;
        }

        toast.error(updated.message);
    }

    const clickOpenModal = (user: ResponseUsers) => {
        setUser(user);
        setIsOpenModal(true);
    }

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table className="table-users">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Telefone</TableCell>
                            <TableCell>Permissão</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell>Data Cadastro</TableCell>
                            <TableCell align="center">Atualizar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            users.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{item.phone_number}</TableCell>
                                    <TableCell>{item.permission}</TableCell>
                                    <TableCell align="center">{item.available ? <FcCheckmark title="Ativo" /> : <FcCancel title="Inativo" />}</TableCell>
                                    <TableCell>{format(new Date(item.created_at), "dd/MM/yyyy")}</TableCell>
                                    <TableCell align="center">
                                        <button 
                                            disabled={item.permission === "admin"} 
                                            onClick={() => item.permission !== "admin" && clickOpenModal(item)}
                                            title={item.permission === "admin" ? 'Não é possível editar este usuário' : 'Redefinir senha'}
                                        >
                                            <BsPencilSquare />
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal 
                body={<ContentModalUpdatePassword data={user} handleChange={handleChangePassword} handleSubmit={handleSubmit} isLoading={isLoading} />} 
                title="Atualização de usuário" 
                openClose={[isOpenModal, setIsOpenModal]}
            />
        </Container>
    )
}

export default UsersList;