import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  transition: 0.5s;

  display: flex;
  flex-direction: row;
`;

export const ContentBody = styled.div`
  width: 100%;
  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  background-color: #F5F7F9;
  box-shadow: inset 5px 5px 10px 0px rgba(29,25,57,0.05);

  > div{
    width: 100%;
    height: 100%;
  }
`;

interface SidebarProps{
  show: boolean;
}

export const Sidebar = styled.div<SidebarProps>`
  width: 250px;
  height: 100vh;
  transition: 0.5s;

  
  @media (max-width: 820px) {
    position: absolute;
    top: 0;
    z-index: 100;
    transform: translateX(${(props) => props.show ? '0' : '-250px' });
  }
`;