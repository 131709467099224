import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 20px;
  .pagination-box{
    width:400px;
    margin:0 auto;
    .paginate{
      cursor:pointer;
      list-style:none;
      display:flex;
      flex-direction:row;
      gap:16px;
      justify-content:center;
    }
    .active{
      color:red;
    }
  }
  .header-button{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    .title{
      color:#1D1939;
      font-weight:bold;
      font-size:20px;
      text-transform:uppercase;
    }
  }
  .not-found{
    display:flex;
    justify-content:center;
    flex-direction:column;
    width: 100%;
    margin: 50px 0px;
    align-items:center;
    gap:32px;
    p{
      color:#666666
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;

  button {
    border: 2px solid #1A1A1A;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 14px 16px;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-wrap: wrap;
  gap: 20px;
`;