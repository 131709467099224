import React from 'react';
import {
  Navigate,
} from 'react-router-dom';
import { useDashboard } from '../context/DashboardContext';
import Header from '../layout/Header';

interface RouteProps {
  component: React.ComponentType;
  path: string;
}

const routesAdmin = [
  "/user/manage"
]

const PrivateRoute: React.FC<RouteProps> = ({ component: Component, path }) => {
  const { getUserData, getToken } = useDashboard();
  const user = getUserData();
  const token = getToken();

  if (user && token) {
    if (routesAdmin.some(item => item === path) && user.permission !== "admin") {
      return <Navigate to='/' />
    } else {
      return(
        <Header>
          <Component />
        </Header>
      )
    }
  } else {
    return <Navigate to='/login' />
  }
}

export default PrivateRoute;
