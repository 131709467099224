import { useEffect, useState } from "react";
import { Container, FilterParams } from "./style"
import DatePicker, { registerLocale } from "react-datepicker";
import { setDate } from "date-fns";
import br from "date-fns/locale/pt-BR";
import TableDotz from "../TableDotz";
import { ResponseDotzReport } from "../../../interfaces/ResponseReport";
import Button from "../../Button";
import { useDashboard } from "../../../context/DashboardContext";
import { Enterprise } from "../../../utils/types/RealtorTypes";
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select } from "@material-ui/core";

registerLocale("pt-BR", br); 

export const Deposit = () => {
    const [filterDateIsActive, setFilterDateIsActive] = useState(false);
    const [isFetchingReport, setIsFetchingReport] = useState(true);
    const [data, setData] = useState<ResponseDotzReport[]>([]);
    const [dataInitial, setDataInitial] = useState<ResponseDotzReport[]>([]);
    const [isAvailable, setIsAvailable] = useState(false);
    const [selectDeposited, setSelectDeposited] = useState();
    const [enterprises, setEnterprises] = useState([] as Enterprise[])
    const [selectedEnterprise, setSelectedEnterprise] = useState<{ cod_enterprise: any, name_enterprise: string }>({} as {cod_enterprise: number, name_enterprise: string})

    const [dateFilter, setDateFilter] = useState<(Date | null)[]>([
        setDate(new Date(), 1),
        new Date(),
    ]);
    const { fetchReportDotzDeposit, fetchEnterprises } = useDashboard();
    const handleSelectDate = (param: any) => {
        setFilterDateIsActive(true);
        setDateFilter(param);
    }
    const handleFilter = () => {
        setIsFetchingReport(true);
        fetchDataReportDotz();
    }
    const fetchDataReportDotz = () => {
        if (dateFilter[0] && dateFilter[1]) {
            fetchReportDotzDeposit(dateFilter[0].toDateString(), dateFilter[1].toDateString(), selectDeposited, selectedEnterprise.cod_enterprise)
                .then((res) => {
                    setData(res);
                    setDataInitial(res);
                    setIsFetchingReport(false);
                    setFilterDateIsActive(false);
                });
            }
    }
    const handleSelectedEnterprise = (e: React.ChangeEvent<{ value: unknown }>) => {
        if(e.target.value !== 0 ) {
          setSelectedEnterprise(enterprises.find(enterprise => enterprise.cod_enterprise === e.target.value) || {cod_enterprise: null, name_enterprise: 'todos'} )
        } else {
          setSelectedEnterprise({cod_enterprise: null, name_enterprise: 'Todos os empreendimentos'})
        }
      } 
    useEffect(() => {
        if(Number(sessionStorage.getItem("permission_level")) <= 2){
        setIsAvailable(true);
        fetchDataReportDotz();
        fetchEnterprises()
            .then((res) => {
                setEnterprises(res)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        
            <Container>
                {isAvailable && 
                <div>
                <FilterParams>
                    <div className="date-filter">
                        <label htmlFor="date-filter">Filtrar data</label>
                        <DatePicker
                            startDate={dateFilter[0]}
                            endDate={dateFilter[1]}
                            onChange={(e) => handleSelectDate(e)}
                            selectsRange
                            locale="pt-BR"
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <FormControl
                          variant="outlined"
                          style={{display: 'flex', flexDirection: 'row', width: '300px'}}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">Selecione o empreendimento</InputLabel>
                            <Select
                                style={{width: '100%'}}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedEnterprise.name_enterprise}
                                onChange={handleSelectedEnterprise}
                                label="Selecione o empreendimento"
                            >
                            <MenuItem value={0}>Todos os empreendimentos</MenuItem>
                            {enterprises.map(enterprise => (
                              <MenuItem key={enterprise.cod_enterprise} value={enterprise.cod_enterprise}>{enterprise.name_enterprise}</MenuItem>
                              ))}
                          </Select>

                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">Selecione</InputLabel>

                            <Select
                                style={{width: '100%', minWidth: '146px'}}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedEnterprise.name_enterprise}
                                onChange={(e) => setSelectDeposited(e.target.value as any)}
                                label="Selecione o empreendimento"
                            >
                            <MenuItem value={"Todos"}>Todos</MenuItem>
                            <MenuItem value={"true"}>Depositados</MenuItem>
                            <MenuItem value={"false"}>Não depositados</MenuItem>
                            
                          </Select>
                    
                    </FormControl>
                    <div className="fetch">
                        <Button onClick={handleFilter}>
                            Buscar
                        </Button>
                    </div>
                </FilterParams>
                <TableDotz isLoading={isFetchingReport} dataReport={data} />
                </div>
                }
            </Container>
    )
}