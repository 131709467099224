import { HttpRequest } from "@aws-sdk/protocol-http";
import { S3RequestPresigner } from "@aws-sdk/s3-request-presigner";
import { parseUrl } from "@aws-sdk/url-parser";
import { Sha256 } from "@aws-crypto/sha256-browser";
import { formatUrl } from "@aws-sdk/util-format-url";
import { env } from "../../config/env";
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";

export const getUrlAwsS3Realtor = async (url: string, filename: string, isPublic = true) => {
  if (isPublic === false && url && filename) {
    try {
        const s3ObjectUrl = parseUrl(url);

        const presigner = new S3RequestPresigner({
          region: env.awsS3Realtor.region,
          credentials: {
            accessKeyId: env.awsS3Realtor.key,
            secretAccessKey: env.awsS3Realtor.secret,
          },
          sha256: Sha256,
        });

      return formatUrl(await presigner.presign(new HttpRequest(s3ObjectUrl)));
    } catch (error) {
      console.log(error);
    }
  }

  return url;
}

export const getBase64FileAwsS3Realtor = async (
  folder: string,
  filename: string | undefined
  ) => {
  switch (folder) {
    case 'file':
      folder = 'files';
      break;

    case 'mission':
      folder = 'missions';
      break;

    case 'announcement':
      folder = 'announcements';
      break;
  
    default:
      break;
  }

  if (folder && filename) {
    const s3Client = new S3Client({
      region: env.awsS3Realtor.region,
      credentials: {
        accessKeyId: env.awsS3Realtor.key,
        secretAccessKey: env.awsS3Realtor.secret,
      },
    });

    const command = new GetObjectCommand({
      Bucket: env.awsS3Realtor.bucket,
      Key: `${folder}/${filename}`,
    });
    
    try {
      const { Body } = await s3Client.send(command);

      return Body?.transformToString('base64');
    } catch (error) {
      console.log(error);
    }
  }
}