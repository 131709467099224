/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { BsFillShieldLockFill } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify"
import Button from "../../components/Button";
import Form from "../../components/Login/Form";
import Input from "../../components/Login/Input";
import Spinner from "../../components/Spinner";
import { ReturnApi } from "../../interfaces/ReturnApi";
import api from "../../services/api";
import { Container } from "./styles";

const RedefinePassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [wasSend, setWasSend] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState<string | undefined>();
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            toast.error('Senhas divergentes');
            return;
        }

        if (password.length < 8) {
            toast.error('Senhas deve conter no mínimo 8 caracteres');
            return;
        }

        setIsLoading(true);

        const send = await requestRedefinePassword(password);

        setIsLoading(false);

        if (send.status === "error") {
            if (send.message === "400" && send.data.includes('new_password')) {                
                toast.error('Password inválido, tente novamente');
            } else if(send.message === "400") {
                toast.error('Seu link pode ter expirado, solicite novamente');
                navigate('/forgot-password');
            } else {
                toast.error('Ocorreu um erro, tente novamente mais tarde')
            }
            return;
        }
        
        setPassword('');
        setConfirmPassword('');
        setWasSend(true);
        toast.success('Atualizado com sucesso!')
    }

    const requestRedefinePassword = async (new_password: string): Promise<ReturnApi<string>> => {
        try {
            const { data } = await api.put('/forgot-password', {
                new_password,
                token
            });

            return{
                status: "success",
                message: "",
                data
            }
        } catch (error: any) {
            return{
                status: "error",
                message: String(error?.response.status),
                data: error?.response.data.message
            }
        }
    }

    const backToLogin = () => {
        navigate('/login');
    }

    useEffect(() => {
        const tokenParam = searchParams.get('token');

        if (!tokenParam) {
            navigate('/forgot-password');
        } else {
            setToken(tokenParam);
        }
    }, [])

    return (
        <Container>
            <div className="content">
                <div className="brand">
                    <div>
                        <img src="/images/loog-hm-estelar-white@2x.png" />
                        <span className="title">Acesso Administrativo</span>
                    </div>
                </div>
                <div className="form">
                    <Form onSubmit={handleSubmit}>
                    {
                        wasSend ? (
                            <>
                                <h3 className="success"><BsFillShieldLockFill /></h3>
                                <span>Senha redefinida com sucesso!</span>
                                <Button onClick={backToLogin}>Fazer login</Button>
                            </>
                        ) : (
                            <>
                                <h3 className="lock-pass"><BsFillShieldLockFill /></h3>
                                <span>Insira a nova senha desejada</span>
                                

                                <Input onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Senha" type="password" name="password" required />
                                <Input onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} placeholder="Confirme a senha" type="password" name="confirm_password" required />

                                <Button disabled={isLoading || wasSend} type="submit">
                                    {
                                        !isLoading ? 'Redefinir' : <Spinner type="secondary" />
                                    }
                                </Button>
                            </>
                        )
                    }
                    </Form>
                </div>
            </div>
            <div className="footer">
                <span>
                    Todos os direitos reservados &copy; HM Engenharia {new Date().getFullYear()}.
                </span>
            </div>
        </Container>
    )
}

export default RedefinePassword;