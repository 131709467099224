import Main from "../../components/ManageUser/Main";

const ManageUser = () => {
    return (
        <div>
            <Main />
        </div>
    )
}

export default ManageUser;