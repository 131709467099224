/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Container } from "./styles";
import { formatDataToCSVAnalytic } from "../../../utils/exportsCSV";
import { format } from "date-fns";
import { ResponseReport } from "../../../interfaces/ResponseReport";
import Table from "../../Table";
import { calcValuesDotz } from "../../../utils/calcValuesDotz";

interface Props {
    dataReport: ResponseReport[];
    isLoading: boolean;
}

const columns = [
    { title: "CPF", attribute_name_tbody: "cpf" },
    { title: "Nome", attribute_name_tbody: "name" },
    { title: "Cod. Coligada", attribute_name_tbody: "cod_coligada" },
    { title: "Coligada", attribute_name_tbody: "name_coligada" },
    { title: "Empreendimento", attribute_name_tbody: "enterprise" },
    { title: "Cod. Departamento", attribute_name_tbody: "cod_department" },
    { title: "Departamento", attribute_name_tbody: "name_department" },
    { title: "Data", attribute_name_tbody: "date" },
    { title: "Desconto DOTZ", attribute_name_tbody: "discount_dotz" },
    { title: "Receita DOTZ", attribute_name_tbody: "income_dotz" },
    { title: "Extrato", attribute_name_tbody: "total" },
];

const TableReportAnalytic = ({ dataReport, isLoading }: Props) => {
    const [dataCSV, setDataCSV] = useState<any[]>([]);

    useEffect(() => {
        setDataCSV(formatDataToCSVAnalytic(dataReport));
    }, [dataReport])

    return (
        <Container>
            <div className="table">
                <div>
                    <h2>Analítico</h2>
                </div>
                <div className="data-export">
                    <CSVLink filename={"Analitico " + format(new Date(), "dd-MM-yyyy-HH-mm")} data={dataCSV}>Exportar CSV</CSVLink>
                </div>
            </div>
            <div className="content">
                <Table 
                    columns={columns} 
                    data={calcValuesDotz(dataReport)} 
                    isLoading={isLoading} 
                    offset={15} 
                    nowrapCell={["cpf"]} 
                />
            </div>
        </Container>
    )
}

export default TableReportAnalytic;