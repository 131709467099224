import api from "../services/api";
import { DataProps } from "../utils/types/RealtorTypes";

export async function fetchMission(filters: any): Promise<DataProps> {
	try {
		const res = await api.get('/realtor/mission',{
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
			params: filters
        });

		const mission: DataProps = res.data
		return mission;
	} catch(error) {
		throw new Error('Erro ao obter a missão.');
	}
}

export async function fetchFile(filters: any): Promise<DataProps> {
	try {
		const res = await api.get('/realtor/file',{
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
			params: filters
        });

		const file: DataProps = res.data;

		return file;
	} catch(error) {
		throw new Error('Erro ao obter o arquivo.');
	}
}

export async function fetchAnnouncement(filters: any): Promise<DataProps> {
	try {
		const res = await api.get('/realtor/announcement',{
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
			params: filters
        });

		const announcement: DataProps = res.data;

		return announcement;
	}  catch(error) {
		throw new Error('Erro ao obter o comunicado.');
	}
}