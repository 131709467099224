import styled from 'styled-components';

export const Form = styled.form`
  max-width: 400px;
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  border: 1.5px solid var(--blue-800);
`;