import api from "../services/api"
import {AnnouncementResponse, AnnouncementProps} from '../utils/types/RealtorTypes'

const useAnnouncement = async ({
    file,
    thumbnail,
    title,
    description,
    cod_enterprise,
    type,
    is_draft,
    is_public,

}: AnnouncementProps): Promise<AnnouncementResponse> => {
    try{
        const res = await api.post('/realtor/announcement', {
            file: file,
            thumbnail: thumbnail,
            title: title,
            description: description,
            type: type,
            is_draft: is_draft,
            is_public: is_public,
            cod_enterprise: cod_enterprise,
        },
        {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }

        );
        return res.data;
    } catch {
        return {} as AnnouncementResponse
    }
}

export default useAnnouncement;