/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container } from "./style";
import { useDashboard } from "../../../context/DashboardContext";
import { ResponseUsers } from "../../../interfaces/ResponseUsers";
import Modal from "../../Modal";
import Button from "../../Button";
import { ContentModal } from "./ContentModal";
import { ContentModalPassword } from "./ContentModalUpdate";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { ReturnApi } from "../../../interfaces/ReturnApi";

interface UpdateUser{
    name?: string;
    cpf?: string;
    phone_number?: string;
    email?: string;
}

interface UpdatePassword{
    old_password?: string;
    new_password?: string;
    confirm_password?: string;
}

const Main = () => {
    const { getUserData, getToken, catchFunctions } = useDashboard();

    const [user, setUser] = useState<ResponseUsers | undefined>();
    const [userUpdate, setUserUpdate] = useState<UpdateUser | undefined>();
    const [userUpdatePassword, setUserUpdatePassword] = useState<UpdatePassword | undefined>();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalPassword, setIsOpenModalPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isEditing, setIsEditing] = useState(false);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        
        if (!isEditing || !userUpdate) {
            return;
        }

        setIsLoading(true);

        const userUpdated = await requestUpdate(userUpdate);

        setIsLoading(false);

        if (userUpdated.status === "success") {
            setIsOpenModal(false);
            setUserUpdate(undefined);
            setIsEditing(false);
            toast.success(userUpdated.message);
            return;
        }

        toast.error(userUpdated.message);
    }

    const requestUpdate = async (body: UpdateUser): Promise<ReturnApi<any>> => {
        try {
            const token = getToken();
            await api.put('/user/update', body, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });

            return{
                status: "success",
                message: "Atualizado com sucesso",
                data: "",
            }
        } catch (error) {
            catchFunctions(error);

            return{
                status: "error",
                message: "Erro ao atualizar",
                data: "",
            }
        }
    }

    const requestUpdatePassword = async (body: UpdatePassword): Promise<ReturnApi<any>> => {
        try {
            const token = getToken();
            await api.put('/user/update-password', body, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });

            return{
                status: "success",
                message: "Atualizado com sucesso",
                data: "",
            }
        } catch (error) {
            catchFunctions(error);

            return{
                status: "error",
                message: "Error ao atualizar",
                data: "",
            }
        }
    }

    const handleSubmitPassword = async (event: any) => {
        event.preventDefault();

        if (!userUpdatePassword) {
            toast.error('Necessário preencher senhas');
            return;
        }

        if (
            !userUpdatePassword?.old_password ||
            userUpdatePassword.old_password === '' || 
            userUpdatePassword.old_password.length < 8
            ) {
            toast.error('Senha atual digitada é inválida');
            return;
        }

        if (
            !userUpdatePassword?.new_password ||
            userUpdatePassword.new_password === '' || 
            userUpdatePassword.new_password.length < 8
            ) {
            toast.error('Nova senha digitada é inválida');
            return;
        }
        
        if (userUpdatePassword.new_password !== userUpdatePassword.confirm_password) {
            toast.error('Senhas divergentes');
            return;
        }

        setIsLoading(true);

        const updated = await requestUpdatePassword({ 
            new_password: userUpdatePassword.new_password,
            old_password: userUpdatePassword.old_password
        });

        setIsLoading(false);

        if (updated.status === "success") {
            setUserUpdatePassword(undefined);
            setIsOpenModalPassword(false);
            toast.success(updated.message);
            return;
        }

        toast.error(updated.message);
    }

    const handleInputUpdate = (value: string, param: string) => {
        setIsEditing(true);
        setUserUpdate({
            ...userUpdate,
            [param]: value
        })
    }

    const handleInputUpdatePassword = (value: string, param: string) => {
        setUserUpdatePassword({
            ...userUpdatePassword,
            [param]: value
        })
    }

    const handleOpenModalUpdate = () => {
        setUserUpdate({
            cpf: user?.cpf,
            email: user?.email,
            name: user?.name,
            phone_number: user?.phone_number
        });
        setIsOpenModal(true);
    }

    const handleOpenModalUpdatePassword = () => {
        setUserUpdatePassword(undefined);
        setIsOpenModalPassword(true);
    }

    useEffect(() => {
        setUser(getUserData());
    },[]);

    return (
        <Container>
            <div className="title">
                <h2>Atualizar dados</h2>
            </div>
            <div className="user-data">
                <div className="title-card">
                    <h3>Dados cadastrais</h3>
                </div>
                <div className="columns">
                    <div className="rows"><span>Nome: <strong>{user?.name}</strong></span></div>
                    <div className="rows"><span>Email: <strong>{user?.email}</strong></span></div>
                    <div className="rows"><span>Telefone: <strong>{user?.phone_number}</strong></span></div>
                    <div className="rows"><span>CPF: <strong>{user?.cpf}</strong></span></div>
                    <div className="rows"><span>Permissão de acesso: <strong>{user?.permission}</strong></span></div>
                </div>
                <div className="button">
                    <Button onClick={handleOpenModalUpdate}>
                        Editar
                    </Button>
                    <Button onClick={handleOpenModalUpdatePassword}>
                        Atualizar senha
                    </Button>
                </div>
            </div>
            <Modal 
                body={<ContentModal data={userUpdate} handleChange={handleInputUpdate} handleSubmit={handleSubmit} isLoading={isLoading} />} 
                title="Atualizar dados" 
                openClose={[isOpenModal, setIsOpenModal]} 
            />
            <Modal 
                body={<ContentModalPassword data={userUpdatePassword} handleChange={handleInputUpdatePassword} handleSubmit={handleSubmitPassword} isLoading={isLoading} />} 
                title="Atualizar senha" 
                openClose={[isOpenModalPassword, setIsOpenModalPassword]} 
            />
        </Container>
    )
}

export default Main;