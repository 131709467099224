import Button from "../../Button";
import Spinner from "../../Spinner";
import { ContainerModal } from "./style";

interface CreateUser{
    name: string;
    cpf: string;
    phone_number: string;
    email: string;
    password: string;
    confirm?: string;
    permission?: "admin" | "suport" | "finance" | "marketing";
}

interface Props{
    data: CreateUser | undefined;
    handleSubmit: (e: any) => Promise<void>;
    handleChange: (a: string, b: string) => void;
    isLoading: boolean;
}

export const ContentModalCreateUser = ({ data, handleChange, handleSubmit, isLoading }: Props) => {
    return (
        <ContainerModal>
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">Nome</label>
                <input value={data?.name ?? ''} onChange={(e) => handleChange(e.target.value, "name")} required minLength={8} type="text" id="name" name="name" />

                <label htmlFor="email">Email</label>
                <input value={data?.email ?? ''} onChange={(e) => handleChange(e.target.value, "email")} required minLength={8} type="email" id="email" name="email" />

                <div className="grouped">
                    <div className="labels">
                        <label htmlFor="cpf">CPF</label>
                        <input value={data?.cpf ?? ''} onChange={(e) => handleChange(e.target.value, "cpf")} required minLength={8} type="text" id="cpf" name="cpf" />
                    </div>
                    <div className="labels">
                        <label htmlFor="phone_number">Telefone</label>
                        <input value={data?.phone_number ?? ''} onChange={(e) => handleChange(e.target.value, "phone_number")} required minLength={8} type="phone" id="phone_number" name="phone_number" />
                    </div>
                </div>

                <div className="grouped">
                    <div className="labels">
                        <label htmlFor="password">Senha</label>
                        <input value={data?.password ?? ''} onChange={(e) => handleChange(e.target.value, "password")} required minLength={8} type="password" id="password" name="password" />
                    </div>
                    <div className="labels">
                        <label htmlFor="confirm">Confirme a senha</label>
                        <input value={data?.confirm ?? ''} onChange={(e) => handleChange(e.target.value, "confirm")} required minLength={8} type="password" id="confirm" name="confirm" />
                    </div>
                </div>

                <label>Permissão</label>
                <div className="group-radio">
                    <input type="radio" id="permission-suport" name="permission" value="suport" />
                    <label htmlFor="permission-suport">Suporte</label>
                    
                    <input type="radio" id="permission-marketing" name="permission" value="marketing" />
                    <label htmlFor="permission-marketing">Marketing</label>
                    
                    <input type="radio" id="permission-admin" name="permission" value="admin" />
                    <label htmlFor="permission-admin">Administrador</label>

                    <input type="radio" id="permission-finance" name="permission" value="finance" />
                    <label htmlFor="permission-finance">Financeiro</label>
                </div>

                <Button type="submit" disabled={isLoading}>
                    {
                        !isLoading ? 'Salvar' : <Spinner type="secondary" />
                    }
                </Button>
            </form>
        </ContainerModal>
    )
}