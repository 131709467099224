import { useNavigate } from "react-router";
import { Container, ItemContent, Enterprise, Type, Description, Status } from "./styles";
import { useDashboard } from "../../context/DashboardContext";
import { DataItem2 } from "../../utils/types/RealtorTypes";
import { getUrlAwsS3Realtor } from "../../utils/aws";
import { useEffect, useState } from "react";
import {MdOutlineEdit} from "react-icons/md";
import Published from "../../icons/Published"
import Archived from "../../icons/Archived"
import { SliceText } from "../Description";
import {DefaultBanner} from "../Marketing/DefaultBanner"
interface ListDataItemProps {
  itemData: DataItem2;
}
const ListDataItem = ({itemData}: ListDataItemProps) => {
  const [urlThumbNail, setUrlThumbNail] = useState<string>('');

  const { setSelectedData } = useDashboard();

  const navigate = useNavigate();

  const handleDetails = () => {
    setSelectedData(itemData)
    navigate('/marketing/article')
  }

  const setSrcImage = async () => {
    const url = await getUrlAwsS3Realtor(itemData.thumbnail_url ?? '', itemData.thumbnail_name ?? '', itemData.is_public);
    setUrlThumbNail(url);
  }

  useEffect(() => {
    setSrcImage()
  }, [])
  
  const type = !itemData.is_draft && itemData.is_available ? 'Publicado' : itemData.is_draft && itemData.is_available ? 'Rascunho' : 'Arquivado'
  console.log(itemData.thumbnail_url)
  return (
    <Container> 
      <div className="div-img">
        {itemData.thumbnail_url === null ?
        <DefaultBanner fileName={itemData.file_name} mimetype={itemData.file_mimetype}/> 
        :
        <img src={urlThumbNail} alt="" />
        }
        
      </div>
      <ItemContent>
        
          <Status type={type} >
          {type === 'Rascunho' && <MdOutlineEdit style={{height: '18px', width: '18px'}}/>}
          {type === 'Publicado' && <Published style={{height: '18px', width: '18px'}}/> }
          {type === 'Arquivado' && <Archived style={{height: '18px', width: '18px'}}/> }
          {type}
        </Status>
        
        
        <h1>{itemData.title}</h1>
        
        {itemData.enterprise?.enterprise_name && <Enterprise>{itemData.enterprise.enterprise_name}</Enterprise>}
           
        {itemData.categories && itemData.categories.length > 0 &&
        <div>
         {itemData.categories.map(cat => (
            <Type>{cat.category_name}</Type>
         ))}
        </div>
        }
        {itemData?.mission_active !== undefined && 
        <Status published={itemData.mission_active ? true : false}>
        {itemData.mission_active? 'Ativa': 'Encerrada'}
        </Status>
        }
        

        <Description>{SliceText(itemData.description ?? '', { start: 0, end: 79 })}</Description>
        <button onClick={handleDetails}>VER DETALHES</button>
      </ItemContent>
    </Container>
  )
}

export default ListDataItem;