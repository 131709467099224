import * as React from "react"


const SvgComponent = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M19 9.5h-4v-6H9v6H5l7 7 7-7zm-8 2v-6h2v6h1.17L12 13.67 9.83 11.5H11zm-6 7h14v2H5v-2z"
        fill="#1A1A1A"
      />
    </svg>
  )
}

export default SvgComponent
