import { Input as InputStyle } from './style';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    type: string
}

const Input = ({ type, ...rest }: Props) => {
    return (
        <InputStyle
            type={type}
            {...rest}
        />
    )
}

export default Input;