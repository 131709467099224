
import { ReactNode, useState } from 'react';
import { Wrapper } from './style';
import {GrClose} from "react-icons/gr"

interface ToastProps {
	status: string;
	Icon: (props: any) => JSX.Element;
	children: ReactNode;
	onClose?: VoidFunction
}

export const Toast = ({status, Icon, children, onClose}: ToastProps) => {
	const [visible, setVisible] = useState(true);

	return (
		<>
		
			<Wrapper $status={status}>
				<div>				
					 <Icon className='icon'/> 
				</div>
			
				<div className='inside-box'>
					<div className='content'>{children}</div>
					<button
						className=''
						type="button"
						title="Fechar"
						onClick={onClose}				
					>
						 <GrClose color='white' style={{fill : "white"}} stroke='white' className='icon'/> 
					</button>	
				</div>
			</Wrapper>
				
		
		</>
	);
};