import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 2px solid #1D1939;
  border-radius: 8px;
  width: 240px;
  height: 528px;

  img {
    width: 100%;
    height: 157px;
    object-fit:cover
  }
  .div-img{
    height:157px;
  }
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;
  flex: 1;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    align-self: flex-start;
    color: #1D1939;
  }

  div {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
    align-items: start;
  }

  button {
    padding: 10px 16px;
    background: #1D1939;
    border-radius: 18px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: auto;
  }
`;

export const Enterprise = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  align-self:start;
  display: flex;
  align-items: center;
  text-align: center;

  border: 1px solid #FFDF5A;
  border-radius: 4px;
  padding: 4px;
  color: #1D1939;
`

export const Type = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  color: #1D1939;

  background: #EAEAEA;
  border-radius: 4px;
  padding: 4px;
`;

export const Description = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  line-break: anywhere;
  align-self:self-start;
  color: #666666;
  li {
    margin-left: 16px;
  }
`;

export const Status = styled.p<{published?: boolean, type?: string}>`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  width: auto;
  gap: 10px;
  align-self: start;

  color: ${props => props.type === 'Arquivado' ? "#FFFFFF" : props => props.type === 'Publicado' ? "#FFFFFF" : props => props.type === 'Rascunho' && "#666"};
  
  background: ${props => props.type === 'Arquivado' ? "#666666" : props => props.type === 'Publicado' ? "#7BC713" : props => props.type === 'Rascunho' && "#EAEAEA"};
  
 
  border-radius: 4px;
  padding: 4px;
   ${({ published }) => published && `
    background: #7BC713;
    color: #FFFFFF;
 `}
 ${({ published }) => published === false && `
 background: #E22D37;
 color: #FFFFFF;
`}
`; 