
const SvgComponent = (props : any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      {...props}
    >
      <path
        d="M20.42 53.063h2.709V57h-2.71C17.43 57 15 54.646 15 51.75v-31.5c0-2.896 2.43-5.25 5.42-5.25h14.014c1.439 0 2.82.55 3.835 1.534l7.664 7.424a5.17 5.17 0 011.583 3.716v10.951h-4.064v-10.5h-6.775c-1.498 0-2.71-1.173-2.71-2.625v-6.563H20.42c-.745 0-1.355.591-1.355 1.313v31.5c0 .722.61 1.313 1.355 1.313zm9.483-9.188h2.71c2.616 0 4.742 2.059 4.742 4.594s-2.126 4.593-4.742 4.593h-1.355v2.626c0 .721-.61 1.312-1.355 1.312s-1.355-.59-1.355-1.313v-10.5c0-.721.61-1.312 1.355-1.312zm2.71 6.563c1.126 0 2.032-.878 2.032-1.97 0-1.09-.906-1.968-2.032-1.968h-1.355v3.938h1.355zm8.129-6.563h2.71c2.244 0 4.064 1.764 4.064 3.938v5.25c0 2.173-1.82 3.937-4.064 3.937h-2.71c-.745 0-1.355-.59-1.355-1.313v-10.5c0-.721.61-1.312 1.355-1.312zm2.71 10.5c.745 0 1.355-.59 1.355-1.313v-5.25c0-.721-.61-1.312-1.355-1.312h-1.355v7.875h1.355zm6.774-9.188c0-.721.61-1.312 1.355-1.312h4.064c.745 0 1.355.59 1.355 1.313 0 .721-.61 1.312-1.355 1.312h-2.71v2.625h2.71c.745 0 1.355.59 1.355 1.313 0 .721-.61 1.312-1.355 1.312h-2.71v3.938c0 .721-.61 1.312-1.354 1.312-.745 0-1.355-.59-1.355-1.313v-10.5z"
        fill="#666666"
      />
    </svg>
  )
}

export default SvgComponent
