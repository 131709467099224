import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  

  h1 {
    color: var(--magenta);
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin-bottom:32px;
  }

  p {
    color: #666666;
    font-size: 16px;
    line-height: 32px;
  }
  .path{
    color:#1D1939;
    font-weight:bold;
    font-size:16px
  }
  .pathname{
    color:#1D1939;
    font-size:16px
  }
  .thumb{
    
    img{
      max-height:384px;
      width:100%;
      object-fit:contain;
    }
  }
  .category{
    display:flex;
    gap:16px;
    margin:32px 0;
    flex-wrap:wrap;
    span{
      color:#666666;
    }

    p, span{
      border-radius: 4px;
      padding:4px;
      font-size:20px;
      line-height:24px;
      font-weight:bold;
    }
    
    .building{
      border: 1px solid #FFDF5A;
      color: rgb(29 25 57);
    }

    .active{
      background-color: rgb(123 199 19);
      color:white;
    }
    .tag{
      background: #EAEAEA;
      color: rgb(29 25 57);
    }
    .not-available{
      background: #666666;
      color:white;
    }
    .status{
     background:#E22D37;
     color:white;
    }
  }
 
  .description{
    li {
      margin-left: 16px;
    }
    a {
      text-decoration: underline;
    }
    display: flex;
    flex-direction: column;
    gap:32px;
    .regulation{
      font-size:20px;
      line-height:24px;
      font-weight:bold;
      color:#666666;
    }
    .file-body{
      display:flex;
      gap:32px;
      align-items: center;
      a{
        text-decoration:none;
        

      }
      button{
        font-family:"Montserrat";
        cursor:pointer;
        background:white;
        display: flex;
        padding: 12px 20px 12px 12px;
        gap:12px;
        border-radius: 24px;
        border: 2px solid black;
        align-items:center;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px
      }
    }
    .pdf-view{
      width:100%;
      height:500px;
    }
  }

  .buttons{
    display:flex;
    margin-top:32px;
    margin-bottom:32px;
    justify-content:space-evenly;
    @media(min-width:540px) {
      gap:32px;
      justify-content:unset;
    }
  }
  .bold{
    font-weight:bold;
  }
  .modalcontent p{
    text-align:center
  }

  .date-mission{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color:#1D1939;
  }
`;
export const ButtonArticle = styled.button<{ $primary?: boolean; $modal?: boolean; $confirmation?: boolean;}>`
  padding:12px 20px;  
  width:${props => props.$modal ? "150px" : "auto"};
  background-image: ${props => props.$primary ? "linear-gradient(to bottom, var(--magenta), var(--magenta-500))" : "linear-gradient(white,white)"};
  height:44px;
  border-radius: 24px;
  border:${props => props.$primary ? "none" : "2px solid #D60B52"};
  color: ${props => props.$primary ? "#fff" : "#D60B52"};
  font-weight: bold;
  font-size: 16px;
  line-height: 0px;
  cursor: pointer;
  text-transform:uppercase;
  margin-top:${props => props.$confirmation ? "32px" : "0"};
  @media(min-width:540px){  
    width:${props => props.$modal ? "222px" : props.$confirmation ? '100%' : 'auto'};
  }
  
`;
export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap:8px;
    margin-top:32px;

    @media(min-width:540px){
      gap:32px;
    }
`;

export const IconWrapper = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    margin:0 auto;
    width:56px;
    height:56px;
    border-radius:56px;
    margin-bottom:32px;
    border: 2px solid var(--magenta);
`;

export const BoldP = styled.p`
    font-size:20px;
    line-height:24px;
    color:#666666;
    font-weight:bold;
`;
