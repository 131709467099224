const SvgComponent = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 33a1.5 1.5 0 011.5-1.5H45a1.5 1.5 0 010 3H28.5A1.5 1.5 0 0127 33zm0 6a1.5 1.5 0 011.5-1.5H45a1.5 1.5 0 010 3H28.5A1.5 1.5 0 0127 39zm0 6a1.5 1.5 0 011.5-1.5H45a1.5 1.5 0 010 3H28.5A1.5 1.5 0 0127 45z"
        fill="#666666"
      />
      <path
        d="M46.667 53.063c.706 0 1.283-.591 1.283-1.313V28.125h-6.417c-1.42 0-2.566-1.173-2.566-2.625v-6.563H26.133c-.706 0-1.283.591-1.283 1.313v31.5c0 .722.577 1.313 1.283 1.313h20.534zM21 20.25c0-2.896 2.302-5.25 5.133-5.25h13.275c1.363 0 2.67.55 3.633 1.534l7.26 7.424a5.313 5.313 0 011.499 3.716V51.75c0 2.896-2.302 5.25-5.133 5.25H26.133C23.302 57 21 54.646 21 51.75v-31.5z"
        fill="#666666"
      />
    </svg>
  )
}

export default SvgComponent
