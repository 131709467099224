import { Breadcrumbs, Link, Typography, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { FiFile } from "react-icons/fi";
import { useDashboard } from "../../context/DashboardContext";
import { useNavigate } from "react-router";
type BreadCrumbsType = {
  name: string;
  url: string
}

interface BreadCrumbsProps {
  crumbs: BreadCrumbsType[]
}

const BreadcrumbsDashboard = ({ crumbs }: BreadCrumbsProps) => {
  
  const { setIsGeneral, isGeneral, setTypeList, typeList } = useDashboard()
  const navigate = useNavigate()
 const handleNavigation = (crumb: string) => {
  setIsGeneral(isGeneral);
  setTypeList(typeList)
  navigate(crumb)
 }
  const styles = ms()
  return (
    <div className={styles.container}>
      <FiFile />
    <Breadcrumbs aria-label="breadcrumbs" separator='>'>
      {crumbs.map((crumb, index, {length} ) => (
        index+1 === length ? 
        <Typography style={{textTransform: 'uppercase', fontFamily: "Montserrat"}} className={styles.typography}>{crumb.name}</Typography> :
        <button onClick={() => handleNavigation(crumb.url)}  style={{fontWeight: 'bold', border: 'none', background: 'transparent', cursor: 'pointer'}} className={styles.link} >{crumb.name}</button>
        
        ))}
    </Breadcrumbs>
        </div>
  )
}

export default BreadcrumbsDashboard;

const ms = makeStyles({
  link: {
    textDecoration: 'none',
    color: '#1D1939',
    fontWeight: 400,
    fontSize: 16,
  },
  container: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 12
  },
  typography: {
    textDecoration: 'underline',
    color: '#1D1939',
    fontWeight: 400,
    fontSize: 16,
  }
})