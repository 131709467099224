/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container } from "./style";
import TableUsers from "../TableUsers";
import { useDashboard } from "../../../context/DashboardContext";
import { ResponseUsers } from "../../../interfaces/ResponseUsers";
import { FiUserPlus } from "react-icons/fi";
import Button from "../../Button";
import Modal from "../../Modal";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { ContentModalCreateUser } from "./ContentModalCreateUser";
import { ReturnApi } from "../../../interfaces/ReturnApi";

interface CreateUser{
    name: string;
    cpf: string;
    phone_number: string;
    email: string;
    password: string;
    confirm?: string;
    id_permission: number;
}

const userState = {
    name: '',
    cpf: '',
    phone_number: '',
    email: '',
    password: '',
    id_permission: 0
}

const Main = () => {
    const { fetchUsersList, getToken, catchFunctions } = useDashboard();

    const [users, setUsers] = useState<ResponseUsers[]>([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [createUser, setCreateUser] = useState<CreateUser>(userState);
    const [isLoading, setIsLoading] = useState(false);
    const [permissionLevel, setPermissionLevel] = useState(0)
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!createUser) {
            toast.error('Dados devem ser preenchidos');
            return;
        }

        if (!createUser.password || createUser.password.length < 8) {
            toast.error('Senha deve conter o minimo de 8 caracteres');
            return;
        }
        
        if (createUser.password !== createUser.confirm) {
            toast.error('Senhas divergentes');
            return;
        }
        
        const permission = event.target.permission.value;

        if (!permission || permission === '') {
            toast.error('Permissão de acesso deve ser selecionada');
            return;
        }

        if (permission === 'admin'){
            setPermissionLevel(1)
        } else if(permission === 'finance'){
            setPermissionLevel(2)
        } else if (permission === 'marketing'){
            setPermissionLevel(3)
        } else if (permission === 'support'){
            setPermissionLevel(4)
        }
        
        const createUserBody: CreateUser = {
            name: createUser.name,
            cpf: createUser.cpf,
            phone_number: createUser.phone_number,
            email: createUser.email,
            password: createUser.password,
            id_permission: permissionLevel
        };
        
        setIsLoading(true);

        const created = await requestCreateUser(createUserBody);

        setIsLoading(false);

        if (created.status === "success") {
            setCreateUser(userState);
            setIsOpenModal(false);
            setUsers([
                ...users,
                {
                    ...createUserBody,
                    available: true,
                    created_at: new Date().toDateString(),
                    id: 0,
                    permission
                }
            ]);
            
            toast.success(created.message);
            return;
        }

        toast.error(created.message);
    }

    const requestCreateUser = async (params: CreateUser): Promise<ReturnApi<ResponseUsers[]>> => {
        try {
            const token = getToken();
            const { data } = await api.post('/user/create', params,{
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            return {
                status: "success",
                message: "Cadastrado com sucesso",
                data
            };
        } catch (error: any) {
            if (error.response?.status === 409) {
                return{
                    message: 'CPF já cadastrado',
                    status: "error",
                    data: []
                };
            }

            catchFunctions(error);

            return{
                status: "error",
                message: "Erro ao cadastrar",
                data: []
            }
        }
    }

    const handleInputCreate = (value: string, param: string) => {
        setCreateUser({
            ...createUser,
            [param]: value
        })
    }

    useEffect(() => {
        fetchUsersList().then(res => {
            setUsers(res);
        })
    },[]);

    return (
        <Container>
            <div className="title">
                <h2>Usuários do sistema</h2>
                <Button onClick={() => setIsOpenModal(true)}>
                    Cadastrar <FiUserPlus />
                </Button>
            </div>
            <TableUsers users={users} />
            <Modal 
                body={(
                    <ContentModalCreateUser 
                    data={createUser} 
                    handleChange={handleInputCreate} 
                    handleSubmit={handleSubmit} 
                    isLoading={isLoading} />
                )} 
                title="Cadastrar novo usuário" 
                openClose={[isOpenModal, setIsOpenModal]} 
            />
        </Container>
    )
}

export default Main;