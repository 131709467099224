import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  padding: 25px;
  gap: 32px;
 
  .sidebar-form{
    display:flex;
    flex-direction:column;

    p{
      margin-bottom:22px;
    }
  }
  #file{
    visibility:hidden;
  }
  .top-banner{
    display:flex;
    justify-content:space-between;
    margin-bottom:32px;
    .esquerda{
      display:flex;
      flex-direction:column;

      p:nth-child(1){
        font-size: 16px;
        color:#1D1939;
        font-weight: 700;
        line-height: 20px;
      }
      p:last-child{
        font-size: 12px;
        line-height: 16px;
      }
    }
    
    .direita {
      display:flex;
      flex-direction:column;
      justify-content:end;
      align-items:end;
      p{
        font-size: 12px;
        line-height:16px;
        color:#1D1939;
        font-weight:bold;
      }
    }
  }

  .div-banner{
    .image{
      height:314px;
      border:1px solid #C4C4C4;
      display:flex;
      justify-content:center;
      align-items:center;
      position:relative;
    }
  }
  
  .form-inputs{
    width:100%;

    @media(min-width:768px){
      width:80%
    }
    display:flex;
    flex-direction:column;
    gap:32px;
    
    .dates{
      display:flex;
      gap:32px;
      
      label{
        font-size:12px;
      }
       div{
        width:50%;
       }
      .dateinput{
        width:100%;
        border:1px solid rgba(0, 0, 0, 0.23);
        border-radius:15px !important;
        background:#F2F2F2;
        padding:12px 16px;
      }
      .MuiInput-underline:before{
       display:none;
      }
      .MuiInput-formControl{
       border:3px solid #1D1939;
       border-radius:15px !important;
       background:#F2F2F2;
       padding:12px 16px;
      }
    }
    
    div{
      p {
        margin-bottom:32px;
      }
    }
  }
  .pdfbutton{
    padding: 12px 20px 12px 12px;
    border-radius: 24px;
    border: 2px solid #666;
    max-width:275px;
    display:flex;
    gap:12px;

    .label1{
      font-size: 16px;
      color:#666666;
      font-weight: 700;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
  .fileButton{
    padding: 12px 20px 12px 12px;
    border-radius: 24px;
    
    background:linear-gradient(to bottom, var(--magenta), var(--magenta-500));
    
    display:flex;
    .label1{
      font-size: 14px;
      color:white;
      font-weight: 700;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
  .attachedfile{
    display:flex;
    align-items:center;
    gap:32px;
    .label2{
      height:44px;
      width:44px;
      background:#EAEAEA;
      display:flex;
      justify-content:center;
      align-items:center;
      border-radius:24px;
    }
  }
  .MuiFormControl-root{
    width:100% !important;
    flex-direction:column;
    flex:2;
    gap:32px;
    @media(min-width:768px){
      flex-direction:row;
    }
  }

  .options-side{
    display:flex;
    flex-direction:column;
    gap:32px;
  }

  #file{
    margin-top:-52px;
  }

  .title{
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color:#1D1939;
  }
  
  .MuiRadio-colorSecondary.Mui-checked, .MuiCheckbox-colorSecondary.Mui-checked{
    color: #1D1939;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border:3px solid #1D1939 !important;
    border-radius:15px;
  }
  .MuiOutlinedInput-input, .MuiOutlinedInput-root{
    padding:12px 16px;
    border-radius:15px !important;
    background:#F2F2F2;
  }
  .MuiSelect-select:focus{
    background:none !important;
  }
  .MuiInputLabel-outlined{
    transform: translate(14px, 25px) scale(1);
  }
  #outlined-size-small-label, .MuiFormLabel-root.Mui-focused{
    background:#F2F2F2;
    color:#666;
  }
  #outlined-size-small{
    width:100%
  }
  .MuiTypography-body1{
    font-family:'Montserrat' !important;
  }
  .buttons{
    display:flex;
    
    flex-direction:column;

    @media(min-width:768px){
      flex-direction:row;
      gap:32px;
    }
  }
  .error{
    color:red;
    margin-top:-32px;
  }
`;

export const ButtonAlt = styled.button<{ $primary?: boolean; $modal?: boolean; $confirmation?: boolean;}>`
  padding:12px 20px; 
  width:${props => props.$modal ? "auto" : "auto"};
  background-image: ${props => props.$primary ? "linear-gradient(to bottom, var(--magenta), var(--magenta-500))" : "linear-gradient(white,white)"};
  height:44px;
  border-radius: 24px;
  border:${props => props.$primary ? "none" : "2px solid #D60B52"};
  color: ${props => props.$primary ? "#fff" : "#D60B52"};
  font-weight: bold;
  font-size: 16px;
  line-height: 0px;
  cursor: pointer;
  text-transform:uppercase;
  margin-top:${props => props.$confirmation ? "32px" : "0"};
  @media(min-width:540px){  
    width:${props => props.$modal ? "auto" : props.$confirmation ? '100%' : 'auto'};
  }
  margin-bottom:32px;
`;

export const ImageWrapper = styled.div<{$image?: string;}>`
  
  display:flex;
  justify-content:center;
  align-items:center;
 
  .image-item__btn-wrapper button{
    position:absolute;
    right: 10px;
    top: 10px;
    border:none;
    background: transparent;
  }
  img{
    max-height:300px;
    max-width: 300px;
    @media(min-width:768px){
      max-height:314px;
      max-width: 1216px;
    }
  }
  button svg{
    width:40px;
    height:40px;
    
    stroke:#D60B52;
  }
`
export const ButtonArticle = styled.button<{ $primary?: boolean; $modal?: boolean; $confirmation?: boolean;}>`
  padding:0px 10px; 
  width:${props => props.$modal ? "150px" : "auto"};
  background-image: ${props => props.$primary ? "linear-gradient(to bottom, var(--magenta), var(--magenta-500))" : "linear-gradient(white,white)"};
  height:44px;
  border-radius: 24px;
  border:${props => props.$primary ? "none" : "2px solid #D60B52"};
  color: ${props => props.$primary ? "#fff" : "#D60B52"};
  font-weight: bold;
  font-size: 16px;
  line-height: 0px;
  cursor: pointer;
  text-transform:uppercase;
  margin-top:${props => props.$confirmation ? "32px" : "0"};
  @media(min-width:540px){  
    width:${props => props.$modal ? "222px" : props.$confirmation ? '100%' : '103px'};
  }
  
`;
export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap:8px;
    margin-top:32px;

    @media(min-width:540px){
      gap:32px;
    }
`;

export const IconWrapper = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    margin:0 auto;
    width:56px;
    height:56px;
    border-radius:56px;
    margin-bottom:32px;
    border 2px solid var(--magenta);
`;

export const BoldP = styled.p`
    font-size:20px;
    line-height:24px;
    color:#666666;
    font-weight:bold;
`;
export const ButtonAdd = styled.button`
  padding: 12px 20px 12px 12px;
  display: flex;
  gap:12px;
  border-radius: 24px;
  border:none;
  color:white;
  font-family:'Montserrat';
  font-size: 14px;
  line-height: 24px;
  background-image: linear-gradient(to bottom, var(--magenta), var(--magenta-500));
  cursor:pointer;

  svg{
    width: 22px;
    height: 22px;
  }
`;