import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: 100%;

  padding: 0.5rem;

  .title {
    padding: 1rem;
    color: var(--blue);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-image: var(--button-secondary);
      svg{
        margin-left: 0.36rem;
        font-weight: bold;
      }
    }
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;

  form{
    display: flex;
    flex-direction: column;
    
    padding: 1rem;

    label{
      font-size: 0.8rem;
      margin-top:0.5rem
    }

    input{
      width: 100%;
      border: 1px solid var(--blue);
      outline: none;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin: 0.5rem 0;
      font-size: 1rem;
    }

    > div.grouped{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.5rem;

      .labels{
        display: flex;
        flex: 1;
        flex-direction: column;
      }
    }

    > div.group-radio{
      width: 100%;
      display: block;

      label{
        margin-right: 1rem;
      }

      input{
        width: auto;
        margin-right: 0.5rem;

        &[type=radio]:checked{
          background-color: var(--blue);
        }
      }
    }

    button{
      max-width: 180px;
      margin-left: auto;
      margin-right: auto;
      background-image: var(--button-secondary);
    }
  }

  @media (max-width: 820px) {
    form{
      > div.grouped{
        flex-direction: column;
        justify-content: flex-start;
        gap: 0;
      }
    }
  }
`;