import TableReportAnalytic from "../TableReportAnalytic";
import { CardReports, Container, FilterParams } from "./style";
import { useDashboard } from "../../../context/DashboardContext";
import { useEffect, useState } from "react";
import Button from "../../Button";
import { setDate } from "date-fns";
import TableReportSynthetic from "../TableReportSynthetic";
import { ResponseReport } from "../../../interfaces/ResponseReport";

import DatePicker, { registerLocale } from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("pt-BR", br); 

const Main = () => {
    const { 
        fetchReportDotz, totalDotzExchangeValue, totalDotzIncomeValue, totalDotzValue 
    } = useDashboard();
    const [data, setData] = useState<ResponseReport[]>([]);
    const [dataInitial, setDataInitial] = useState<ResponseReport[]>([]);
    const [filterDateIsActive, setFilterDateIsActive] = useState(false);
    const [coligadaSelected, setColigadaSelected] = useState("0");
    const [isFetchingReport, setIsFetchingReport] = useState(true);
    const [isAvailable, setIsAvailable] = useState(false);

    const [optinsColigada, setOptionsColigada] = useState([
        {
            label: "Todas",
            value: "0"
        }
    ]);
    const [dateFilter, setDateFilter] = useState<(Date | null)[]>([
        setDate(new Date(), 1),
        new Date(),
    ]);

    const handleSelectDate = (param: any) => {
        setFilterDateIsActive(true);
        setDateFilter(param);
    }

    const filterColigada = (data: ResponseReport[]) => {
        if (coligadaSelected === '0') {
            return data;
        }
        if (coligadaSelected !== '') {
            return data.filter(rp => rp.cod_coligada === coligadaSelected);
        }
        return data;
    }

    const handleOptionsColigada = (param: ResponseReport[]) => {
        param.forEach(rp => {
            if (!optinsColigada.some(i => i.value === rp.cod_coligada)) {
                const current = optinsColigada
                current.push({ label: rp.cod_coligada, value: rp.cod_coligada });
                current.sort((a, b) => Number(a.value) - Number(b.value));

                setOptionsColigada(current);
            }
        })
    }

    const handleFilter = () => {
        if (!filterDateIsActive) {
            setData(filterColigada(dataInitial));
        } else {
            setIsFetchingReport(true);
            fetchDataReportDotz();
        }
    }

    const fetchDataReportDotz = () => {
        if (dateFilter[0] && dateFilter[1]) {
            fetchReportDotz(dateFilter[0].toDateString(), dateFilter[1].toDateString())
                .then((res) => {
                    setData(filterColigada(res));
                    setDataInitial(res);
                    handleOptionsColigada(res);
                    setIsFetchingReport(false);
                    setFilterDateIsActive(false);
                });
            }
    }

    useEffect(() => {
        if(Number(sessionStorage.getItem("permission_level")) <= 2){
        setIsAvailable(true);
        fetchDataReportDotz();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container>
            {isAvailable && 
            <>
            <CardReports>
                <div className="card blue">
                    <span>TOTAL DOTZ TROCADOS</span>
                    <span>{totalDotzExchangeValue.toLocaleString('pt-BR')}</span>
                </div>
                <div className="card orange">
                    <span>TOTAL EXTRATO DOTZ</span>
                    <span>{totalDotzValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
                <div className="card green">
                    <span>TOTAL RECEITA DOTZ</span>
                    <span>{totalDotzIncomeValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
            </CardReports>
            <div>
                <FilterParams>
                    <div className="date-filter">
                        <label htmlFor="date-filter">Filtrar data</label>
                        <DatePicker
                            startDate={dateFilter[0]}
                            endDate={dateFilter[1]}
                            onChange={(e) => handleSelectDate(e)}
                            selectsRange
                            locale="pt-BR"
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div className="coligada-filter">
                        <label htmlFor="select-filter">Selecione a coligada</label>
                        <select
                            id="select-filter"
                            defaultValue="0"
                            name="select-filter"
                            onChange={(e) => setColigadaSelected(e.target.value)}
                        >
                            {
                                optinsColigada.map(op => (
                                    <option key={op.value} value={op.value}>
                                        {op.label}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="fetch">
                        <Button onClick={handleFilter}>
                            Buscar
                        </Button>
                    </div>
                </FilterParams>
                <TableReportAnalytic isLoading={isFetchingReport} dataReport={data} />
                <TableReportSynthetic isLoading={isFetchingReport} dataReport={data} />
            </div>
            </>
            }
            
        </Container>
    )
}

export default Main;