import Button from "../../Button";
import Spinner from "../../Spinner";
import { ContainerModal } from "./style";

interface Props{
    data: any;
    handleSubmit: (e: any) => Promise<void>;
    handleChange: (a: string, b: string) => void;
    isLoading: boolean;
}

export const ContentModalPassword = ({ data, handleSubmit, handleChange, isLoading }: Props) => (
    <ContainerModal>
        <form onSubmit={handleSubmit}>
            <label htmlFor="old_password">Senha atual</label>
            <input value={data?.old_password ?? ''} onChange={(e) => handleChange(e.target.value, "old_password")} required minLength={8} type="password" id="old_password" name="old-password" />

            <label htmlFor="new_password">Nova senha</label>
            <input value={data?.new_password ?? ''} onChange={(e) => handleChange(e.target.value, "new_password")} required minLength={8} type="password" id="new_password" name="new_password" />

            <label htmlFor="confirm">Confime a nova senha</label>
            <input value={data?.confirm_password ?? ''} onChange={(e) => handleChange(e.target.value, "confirm_password")} required minLength={8} type="password" id="confirm" name="confirm" />

            <Button type="submit" disabled={isLoading}>
                {
                    !isLoading ? 'Salvar' : <Spinner type="secondary" />
                }
            </Button>
        </form>
    </ContainerModal>
);