const SvgComponent = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={73}
      height={72}
      viewBox="0 0 73 72"
      fill="none"
      {...props}
    >
      <path
        d="M25.094 51.75v-31.5c0-.722.57-1.313 1.267-1.313h12.674V25.5c0 1.452 1.133 2.625 2.535 2.625h6.336V51.75c0 .722-.57 1.313-1.267 1.313H26.361c-.697 0-1.267-.591-1.267-1.313zM26.36 15c-2.796 0-5.07 2.354-5.07 5.25v31.5c0 2.896 2.274 5.25 5.07 5.25H46.64c2.796 0 5.07-2.354 5.07-5.25V27.674c0-1.395-.531-2.732-1.482-3.716l-7.176-7.424A4.966 4.966 0 0039.47 15H26.36zm7.2 19.138a1.859 1.859 0 00-2.669-.345c-.832.665-.974 1.904-.333 2.765l3.533 4.692-3.525 4.7c-.641.862-.499 2.092.333 2.765.832.672 2.02.517 2.67-.345l2.93-3.913 2.939 3.905a1.859 1.859 0 002.67.345c.83-.665.973-1.904.332-2.765l-3.533-4.692 3.525-4.7c.642-.862.499-2.092-.333-2.765-.831-.672-2.02-.517-2.67.345l-2.93 3.913-2.939-3.905z"
        fill="#666666"
      />
    </svg>
  )
}

export default SvgComponent
