/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import ReactPaginate from "react-paginate";
import { useState, useEffect } from "react"
import { Container, Pagination, TableContainer } from "./style";

interface Props{
    columns: { title: string, attribute_name_tbody: string }[];
    data: any[];
    isLoading: boolean;
    offset: number;
    nowrapCell?: string[];
}

interface Column{
    columns: { title: string; },
    minWidth?: number;
}

interface Row{
    data: any;
    index: number;    
    nowrapCell?: string[];
    columns: { title: string, attribute_name_tbody: string }[];
}

const Columns = ({ columns }: Column) => (
    <th key={columns.title}>{columns.title}</th>
);

const Rows = ({ data, columns, index, nowrapCell }: Row) => {
    return (
        <tr className={index % 2 ? 'bg' : ''}>
            {
                columns.map(item => (
                    <td key={item.title} 
                        style={{ whiteSpace: nowrapCell?.some(i => i === item.attribute_name_tbody) ? 'nowrap' : 'normal' }}>
                        {data[item.attribute_name_tbody]}
                    </td>
                ))
            }
        </tr>
    )
}

const Table = ({ columns, data, isLoading, offset, nowrapCell }: Props) => {
    const [pager, setPager] = useState(0);
    const [pageData, setPageData] = useState<any[]>([]);

    offset = offset ?? 0;

    const handlePageClick = (selected: number) => {
        const sliced = data.slice(selected * offset, (selected + 1) * offset);

        setPageData(sliced);
        setPager(selected);
    }

    useEffect(() => {
        handlePageClick(0);
    }, [data])

    return (      
        <Container>
            <TableContainer>
                <thead>
                    <tr>
                        {
                            columns.map((item, i) => <Columns key={i} columns={item} />)
                        }
                    </tr>
                </thead>
                <tbody>
                {
                    pageData.length > 0 && !isLoading ? (
                        pageData.map((item, i) => <Rows nowrapCell={nowrapCell} key={i} index={i} columns={columns} data={item} />)
                    ) : 
                    pageData.length === 0 && !isLoading ? (
                        <tr className="loading">
                            <td>
                                <span>Nenhum registro encontrado</span>
                            </td>
                        </tr>
                    ) : (
                        <tr className="loading">
                            <td>
                                <img src="/images/loading.svg" style={{ width: 16, height: 16 }} />
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </TableContainer>
            <div>
                <Pagination>
                    <ReactPaginate
                        previousLabel={''}
                        nextLabel={''}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={data.length === 0 ? 1 : Math.ceil(data.length / offset)}
                        marginPagesDisplayed={5}
                        pageRangeDisplayed={2}
                        onPageChange={(e) => handlePageClick(e.selected)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        initialPage={pager}
                        forcePage={pager}
                    />
                </Pagination>
            </div>
        </Container>
    );
}

export default Table;