import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  background-color: rgba(29, 25, 57, 1);

  div.content{
    flex: 1;
    display: flex;
    flex-direction: row;

    .brand, .form{
      flex: 50%;
      display: flex;
      justify-content: center;
      padding: 1rem;
    }

    .brand{
      > div {
        width: 50%;
        max-width: 300px;
        min-width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      flex-direction: column;
      align-items: flex-end;    

      span.title{
        color: #fff;
        font-weight: bold;
        font-size: 1.5rem;
        max-width: 380px;
      }
      
      img{
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    .form{
      flex-direction: column;
      align-items: flex-start;

      span{
        color: #fff;
        text-align: center;
        margin-bottom: 1.5rem;
        opacity: 0.9;
        font-size: 1.2rem;
      }

      .success{
        svg{
          font-size: 2rem;
          margin-bottom: 1rem;
          color: var(--green);
        }
      }
      .lock-pass{
        svg{
          font-size: 2rem;
          color: var(--blue-800);
          margin-bottom: 1rem;
        }
      }

      a.forgot-password{
        color: var(--magenta-500);
        margin: 0.5rem auto 1rem auto;
        text-decoration: none;
      }

      a.forgot-password:hover{
        text-decoration: underline;
      }

      button:disabled{
        cursor: not-allowed;
      }
    }

    @media (max-width: 768px){
      flex-direction: column;

      .form{
        flex: 60%;
        justify-content: start;
        align-items: center;
      }

      .brand{
        flex: 40%;
        align-items: center;
        justify-content: end;

        > div{    
          width: 100%;
          min-width: 200px;
        }

        span.title{
          font-size: 1rem;
        }
      }
    }
  }

  div.footer{
    width: 100%;
    height: 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    span{
      color: #fff;
      text-align: center;
    }
  }
`;