import styled from "styled-components";

export const Wrapper = styled.div<{ $status?: string; }>`
    background: ${props => props.$status == 'error' ? '#D60B52' : props.$status == 'success' ? '#7BC713' : props.$status == 'draft' ? '#756D94' : ''};
    max-width: 64rem;
    padding:16px;
    display:flex;
    flex-direction:row;
    margin:16px;
    gap:16px;
    align-items:center;
    border-radius: 1.25rem;
    position:fixed;
    right: 20px;
    z-index:20;
    @media(min-width:768px){
        margin:0;
        width:480px;
    }

    .inside-box{
        display:flex;
        flex-direction:flex-row;
        justify-content:space-between;
        width:100%;
        align-items:center;
    }

    .content{
        color:white;
        font-size:12px;
        font-weight:700;
        text-align:left;

        @media(min-width:768px){
            width:350px;
        }
    }
    button{
        background: transparent;
        border: none;
        
    }
    .icon{
        width:24px;
        height:24px;
        stroke:white;
        fill:white;
        align-self:center;
        path{
            stroke:white;
        }
    }
`