import { ReactNode, useState } from "react";
import Aside from "../Aside";
import TopBar from "../TopBar";
import { Container, Content, ContentBody, Sidebar } from "./style";

interface Props{
    children: ReactNode
}

const Header = ({ children }: Props) => {
    const [isShow, setIsShow] = useState(false);

    const handleShowSidebar = () => {
        setIsShow(!isShow);
    }

    return(
        <Container>
            <Sidebar show={isShow}>
                <Aside setIsShowSidebar={handleShowSidebar} />
            </Sidebar>
            <ContentBody>
                <TopBar setIsShowSidebar={handleShowSidebar} isShowSidebar={isShow} />
                <Content>
                    {children}
                </Content>
            </ContentBody>
        </Container>
    )
}

export default Header;