import styled from "styled-components";

export const Button = styled.button`
  border-radius: 2rem;
  padding: 0.5rem 2rem;
  margin: 1rem;
  min-width: 10rem;
  border: none;
  background-image: linear-gradient(to bottom, var(--magenta), var(--magenta-500));
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
`;