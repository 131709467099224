import React from "react"
import { Form as FormStyle } from './style';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  children: React.ReactNode
  className?: string
  onSubmit?: React.FormEventHandler<HTMLFormElement>
}

const Form = ({ children, onSubmit, ...rest }: Props) => {
  return (
    <FormStyle
      onSubmit={onSubmit}
      {...rest}
    >
      {children}
    </FormStyle>
  )
}

export default Form;
