import styled from "styled-components";

interface Props{
  isShowSidebar: boolean;
}

export const Container = styled.div<Props>`
  width: 100%;
  height: 10vh;
  max-height: 90px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem;

  > div{
    width: 100%;
    height: 100%;
  }

  > div:first-child{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    button{
      display: none;
      visibility: hidden;
      overflow: hidden;

      margin-left: 0.5rem;
      cursor: pointer;
      border: none;
      background-color: transparent;
      align-items: center;
      color: var(--blue);
      
      svg{
        width: 2rem;
        height: 2rem;
      }
    }

    @media (max-width: 820px) {
      button{
        display: flex;
        visibility: visible;
      }
    }
  }

  > div:last-child{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 30%;

    button{
      cursor: pointer;
      padding: 0.5rem 1rem;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: bold;
      color: var(--blue);

      svg{
        margin-left: 0.5rem;
        margin-top: 0.36rem;
      }
    }
  }
`;