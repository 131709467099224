/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"
import Button from "../../components/Button";
import Form from "../../components/Login/Form";
import Input from "../../components/Login/Input";
import Spinner from "../../components/Spinner";
import { ReturnApi } from "../../interfaces/ReturnApi";
import api from "../../services/api";
import { Container } from "./styles";
import { LoginType } from "../../utils/types/LoginType";

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);

        const target = event.target;

        const login = await requestLogin(target.email.value, target.password.value)

        setIsLoading(false);
        if (login.status === "success" &&  login.data) {
            sessionStorage.setItem("token", login.data.access_token);
            sessionStorage.setItem("permission_level", login.data.permission_level + '')
            const user = await requestGetUserData(login.data.access_token);

            if (user.status === "success") {
                sessionStorage.setItem("user-data", JSON.stringify(user.data));
                if(sessionStorage.getItem("permission_level") === '3'){
                    navigate('/marketing')
                } else {
                    navigate('/');

                }
                return;
            }
            
            toast.error('Erro interno, contate o suporte');
        } else {
            login.message === "401" ?
                toast.error('Email e/ou senha inválidos.') :
                toast.error('Erro interno, contate o suporte');
        }

    }

    const requestLogin = async (email: string, password: string): Promise<ReturnApi<LoginType>> => {
        try {
            const { data } = await api.post('/login', {
                email,
                password
            });

            return{
                status: "success",
                message: "",
                data
            }
        } catch (error: any) {
            return{
                status: "error",
                message: String(error?.response.status),
                data: {access_token: '', permission_level: 0, permission: ''}
            }
        }
    }

    const requestGetUserData = async (token: string): Promise<ReturnApi<any>> => {
        try {
            const { data } = await api.get('/user/data', {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });

            return{
                status: "success",
                message: "",
                data
            }
        } catch (error: any) {
            return{
                status: "error",
                message: String(error?.response.status),
                data: []
            }
        }
    }

    return (
        <Container>
            <div className="content">
                <div className="brand">
                    <div>
                        <img src="/images/loog-hm-estelar-white@2x.png" />
                        <span className="title">Acesso Administrativo</span>
                    </div>
                </div>
                <div className="form">
                    <Form onSubmit={handleSubmit}>
                        <label htmlFor="email">E-mail</label>
                        <Input type="email" name="email" required />

                        <label htmlFor="password">Senha</label>
                        <Input type="password" name="password" required />

                        <a href="/forgot-password" className="forgot-password">Esqueceu sua senha?</a>

                        <Button disabled={isLoading} type="submit">
                            {
                                !isLoading ? 'Entrar' : <Spinner type="secondary" />
                            }
                        </Button>
                    </Form>
                </div>
            </div>
            <div className="footer">
                <span>
                    Todos os direitos reservados &copy; HM Engenharia {new Date().getFullYear()}.
                </span>
            </div>
        </Container>
    )
}

export default Login;