const SvgComponent = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={73}
      height={72}
      viewBox="0 0 73 72"
      fill="none"
      {...props}
    >
      <path
        d="M26.496 53.063a1.31 1.31 0 01-1.3-1.313v-31.5a1.31 1.31 0 011.3-1.313H30.4A1.31 1.31 0 0031.7 20.25h2.602a1.31 1.31 0 001.302-1.313h3.903V25.5a2.61 2.61 0 002.602 2.625h6.506V51.75a1.31 1.31 0 01-1.301 1.313H26.496zm0-38.063c-2.87 0-5.204 2.354-5.204 5.25v31.5c0 2.896 2.334 5.25 5.204 5.25h20.819c2.87 0 5.205-2.354 5.205-5.25V27.674a5.275 5.275 0 00-1.521-3.716l-7.368-7.424A5.165 5.165 0 0039.955 15H26.496zm3.904 9.188A1.31 1.31 0 0031.7 25.5h2.602a1.31 1.31 0 001.302-1.313 1.31 1.31 0 00-1.302-1.312h-2.602a1.31 1.31 0 00-1.301 1.313zm0 5.25A1.31 1.31 0 0031.7 30.75h2.602a1.31 1.31 0 001.302-1.313 1.31 1.31 0 00-1.302-1.312h-2.602a1.31 1.31 0 00-1.301 1.313zm-.512 5.89l-1.92 7.226c-.113.443-.17.894-.17 1.346 0 2.887 2.342 5.225 5.204 5.225 2.863 0 5.205-2.338 5.205-5.225 0-.452-.057-.91-.171-1.346l-1.911-7.235a2.606 2.606 0 00-2.513-1.952H32.4c-1.179 0-2.212.796-2.512 1.952v.008zm1.813 7.234h2.602c.716 0 1.302.591 1.302 1.313a1.31 1.31 0 01-1.302 1.313h-2.602a1.31 1.31 0 01-1.301-1.313 1.31 1.31 0 011.301-1.313z"
        fill="#666666"
      />
    </svg>
  )
}

export default SvgComponent
