/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Modal } from "@material-ui/core"
import {Container, ButtonArticle, ButtonGroup, IconWrapper, BoldP} from "./styles"
import { useEffect, useState } from "react"
import useWindowDimensions from '../../utils/useWindowDimensions';
import {MdOutlineDescription} from "react-icons/md";
import { useNavigate } from "react-router";
import { useDashboard } from "../../context/DashboardContext";
import api from "../../services/api";
import BreadcrumbsDashboard from "../../components/BreadcrumbsDashboard";
import Find from "../../icons/Find"
import Download from "../../icons/Download"
import { getBase64FileAwsS3Realtor, getUrlAwsS3Realtor } from "../../utils/aws";
import { TextToHtml } from "../../components/Description";
import { addDays } from "date-fns";
import { DefaultBanner } from "../../components/Marketing/DefaultBanner";

const Article = () => {
    const { selectedData,  setIsEdit, setSelectedData, breadCrumbsName, typeList } = useDashboard()
    const [open, setOpen] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [urlThumbNail, setUrlThumbNail] = useState<string>('');
    const [urlFile, setUrlFile] = useState<string>('');

    const enterprise = selectedData.enterprise?.enterprise_name
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setIsDelete(false);
    };
    
    const handleDelete = async () => {
        setIsDelete(true);
        try{
            await api.delete(`/realtor/${typeList}/${selectedData.id}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
            })
            setTimeout(function() {
              navigate('/marketing/list-data');
            }, 2500);
          } catch{
            return false
          }
    }

    const { width } = useWindowDimensions();
    const navigate = useNavigate();

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width && width < 540 ? '300px' : '540px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        textAlign: 'center'
    };

    const handleEdit = () => {
    setIsEdit(true)
    setSelectedData(selectedData)
    if(selectedData.type !== 'archive' ||  'announcement'){
        navigate('/marketing/manage-content/mission')
        }
        if(selectedData.type === 'archive'){
        navigate('/marketing/manage-content/archive')
    
        }
        if(selectedData.type === 'announcement'){
        navigate('/marketing/manage-content/announcement')
    
        }
    }
    const crumbs = [
        {name: 'MARKETING', url: '/marketing'},
        {name: breadCrumbsName, url: '/marketing/list-data'},
        {name: selectedData.title ? selectedData.title : '', url: ''}
    ]
    
    const handleDownload = async () => {
      try {
        const base64 = await getBase64FileAwsS3Realtor(typeList, selectedData.file_name);

        if (!base64) throw new Error('base64 not found');

        const link = document.createElement('a');
        link.href = `data:${selectedData.file_mimetype};base64,${base64}`;
        link.setAttribute('download', selectedData.file_name || "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Erro ao fazer o download:', error);
      }
    };

    const setSrcImage = async () => {
        const url = await getUrlAwsS3Realtor(selectedData.thumbnail_url ?? '', selectedData.thumbnail_name ?? '', selectedData.is_public);
        setUrlThumbNail(url);
    }
    

    useEffect(() => {
        setSrcImage()

      }, [])
      const setSrcFile = async () => {
        const url = await getUrlAwsS3Realtor(selectedData.file_url ?? '', selectedData.file_name ?? '', selectedData.is_public);
        setUrlFile(url);
      }
    
      useEffect(() => {
        setSrcFile()
      }, [])

    return (
        <Container>
            <BreadcrumbsDashboard crumbs={crumbs} />
            {selectedData.thumbnail_url == null ?
                <div style={{minHeight: '384px'}}>
                    <DefaultBanner fileName={selectedData.file_name} mimetype={selectedData.file_mimetype}/> 
                </div>
                :
                <div className="thumb">
                    <img alt="i" src={urlThumbNail || ""}/>
                </div>
             }
            <div className="content">
                <div className="category">
                    <span>Categorias:</span>
                    {enterprise !== undefined && 
                        <p className="building">{enterprise}</p>

                    }
                    {selectedData.is_available && selectedData?.mission_active && 
                        <p className={selectedData.mission_active ? "active" : "status"}>{selectedData.mission_active ? "Ativa" : "Encerrada"}</p>

                    }

                    {selectedData.categories && selectedData.categories?.length > 0  && 
                        selectedData.categories.map((cat) => (
                            <p key={cat.id} className="tag">{cat.category_name}</p>

                        ))
                    }
                    {selectedData.is_available === false ?
                     <p className="not-available">Arquivado</p>
                     : selectedData.is_draft ?
                     <p className="tag">Rascunho</p>
                     :
                     <p className="active">Publicado</p>
                    }

                </div>
                <h1>{selectedData.title}</h1>
                <div className="description">
                    {typeList === 'file' &&
                    <>
                        <div className="file-body">
                            <p>{selectedData.file_name}</p>
                            {selectedData.file_mimetype === 'application/pdf' && 
                                <a href={urlFile} target="_blank" rel="noreferrer"><button><Find />Abrir no navegador</button></a>
                            }
                            <button onClick={handleDownload}><Download/>Fazer download do arquivo</button>
                        </div>
                        <div>
                            {selectedData.file_mimetype === 'application/pdf' && 
                            <>  
                            <object className="pdf-view"  aria-label="doc" data={urlFile}></object>
                             
                            </>
                            }
                             {(selectedData.file_mimetype === 'video/mp4' || selectedData.file_mimetype === 'video/avi') && 
                            <>
                               <video controls src={urlFile} />
                            </>
                            }
                            {(selectedData.file_mimetype === 'image/jpg' || selectedData.file_mimetype === 'image/png' || selectedData.file_mimetype === 'image/gif' || selectedData.file_mimetype === 'image/jpeg') && 
                                <img style={{maxWidth: '100%'}} src={urlFile} alt="file"/>
                            }
                        </div>
                    </>
                    }
                    {selectedData.start_mission && selectedData.end_mission &&
                    <p className="date-mission">{`Missão válida de ${new Date(selectedData.start_mission).toLocaleDateString('pt-BR', {timeZone: 'UTC'})} à ${new Date(selectedData.end_mission).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}`}</p>
                    }
                    <div style={{wordBreak: "break-word", color:"#666666"}}>
                    {TextToHtml(selectedData.description ?? '')}
                    </div>
                    {selectedData.regulation_name && 
                    <p className="regulation">Regulamento: {selectedData.regulation_name}</p>
                    }
                    <p>Publicado em {selectedData.created_at && new Date(selectedData.created_at).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}.</p>
                    <p>{`${typeList === 'mission' ? '*Essa é uma missão' : typeList === 'announcement' ? '*Esse é um comunicado' : typeList === 'file' && '*Esse é um arquivo'} ${selectedData.is_public ? "Público" : "Privado"}`}</p>
                    {/* <p className="bold">Número de Compartilhamentos: {mock.shares}</p> */}
                </div>
                <div className="buttons">
                    {selectedData.is_available === false ? '' 
                    :
                    <>
                     <ButtonArticle $primary onClick={handleEdit}>Editar</ButtonArticle>
                    <ButtonArticle onClick={handleOpen}>Arquivar</ButtonArticle>
                    </>
                    }
                   
                </div>
            </div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    {!isDelete ? 
                    <div className="modalcontent">
                        <IconWrapper>
                            <MdOutlineDescription />
                        </IconWrapper>
                        <BoldP>
                             {selectedData.type === 'archive' && 'Tem certeza que deseja arquivar este arquivo?'}
                            {selectedData.type === 'announcement' && 'Tem certeza que deseja arquivar este comunicado?'}
                            {selectedData.type === 'mission' && 'Tem certeza que deseja arquivar esta missão?'}                         
                        </BoldP>
                        <ButtonGroup>
                            <ButtonArticle $primary $modal onClick={handleDelete}>Arquivar</ButtonArticle>
                            <ButtonArticle $modal onClick={handleClose}>Cancelar</ButtonArticle>
                        </ButtonGroup>
                    </div>
                    :
                    <div>
                         <IconWrapper>
                            <MdOutlineDescription />
                        </IconWrapper>
                        <BoldP>
                            {selectedData.type === 'archive' && 'O arquivo foi arquivado com sucesso'}
                            {selectedData.type === 'announcement' && 'O comunicado foi arquivado com sucesso'}
                            {selectedData.type === 'mission' && 'O arquivo foi arquivado com sucesso'} 
                        </BoldP>
                        <ButtonArticle $confirmation onClick={handleClose}>Voltar</ButtonArticle>
                    </div>
                    }
                    
                </Box>     
            </Modal>
        </Container>
    )
}

export default Article