import { FormControl, TextField, RadioGroup, FormControlLabel, Radio, InputLabel, Select, MenuItem, Box, Modal } from "@material-ui/core";
import {MdOutlineDescription} from "react-icons/md";
import BreadcrumbsDashboard from "../../../../components/BreadcrumbsDashboard";
import { Container, ButtonAlt, ImageWrapper, ButtonArticle, ButtonGroup, IconWrapper, BoldP, ButtonAdd } from "./styles";
import { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import ImageUploading , { ImageListType }  from 'react-images-uploading';
import { FiEdit } from "react-icons/fi";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { useDashboard } from "../../../../context/DashboardContext";
import useAnnouncement from "../../../../hooks/useAnnouncement";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../../components/Toast";
import {BiBell} from "react-icons/bi"
import {BsCheck2Circle} from "react-icons/bs"
import useEditAnnouncement from "../../../../hooks/useEditAnnouncement";
import api from "../../../../services/api";
import { Enterprise } from "../../../../utils/types/RealtorTypes";
import {AiOutlinePlus} from "react-icons/ai"
import { getUrlAwsS3Realtor } from "../../../../utils/aws";
import { TextArea } from "../../../../components/Description";

const ManageContent = () => {
  
  const { selectedData, fetchEnterprises, isEdit, setTypeList, setIsGeneral, setBreadCrumbsName } = useDashboard()
  const isEdit2 = isEdit;
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width && width < 540 ? '300px' : '540px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
  };
  const crumbs = [
    {name: 'MARKETING', url: '/marketing'},
    {name: isEdit ? 'Editar comunicado' : 'Adicionar novo comunicado', url: '/marketing/manage-content/announcement'}
  ]
  const [selectedEnterprise, setSelectedEnterprise] = useState<{ cod_enterprise: number, enterprise_code?: number, name_enterprise: string, enterprise_name?: string }>(selectedData.enterprise || {} as { cod_enterprise: number, name_enterprise: string, enterprise_code?: number, enterprise_name?: string })
  const [hasBuilding, setHasBuilding] = useState(isEdit2 ? selectedData.enterprise !== null ? 'sim' : 'nao' : '')
  const [images, setImages] = useState([] as ImageListType);
  const [isPublic, setIsPublic] = useState(isEdit2 ? selectedData.is_public === true ? 'publico' : 'privado' : '');
  const [imageEdit, setImageEdit] = useState(isEdit2 ? selectedData.thumbnail_url : '');
  const [imageEditNew, setImageEditNew] = useState({} as ImageListType);
  const [urlThumbNail, setUrlThumbNail] = useState<string>('');
  const [enterprises, setEnterprises] = useState([] as Enterprise[]);
  const [title, setTitle] = useState<string>(isEdit2 ? selectedData.title ? selectedData.title : '' : '');
  const [description, setDescription] = useState<string>(isEdit2 ? selectedData.description ? selectedData.description : '' : '');
  const [open, setOpen] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isValidTitle, setIsValidTitle] = useState<boolean>(true)
  const [isValidDescription, setIsValidDescription] = useState<boolean>(true)
  const [isValidOption, setIsValidOption] = useState<boolean>(true)
  const [isValidRelated, setIsValidRelated] = useState<boolean>(true)
  const [isValidImage, setIsValidImage] = useState<boolean>(true)
  const [validationToast, setValidationToast] = useState<boolean>(false)
  const [draftToast, setDraftToast] = useState<boolean>(false)
  const [toastNotification, setToastNotification] = useState(false);
  const [saveToast, setSaveToast] = useState<boolean>(false)
  const [disabledButtons, setDisabledButtons] = useState<boolean>(false);
  const maxNumber = 1;


  const handleSelectedEnterprise = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedEnterprise(enterprises.find(enterprise => enterprise.cod_enterprise === e.target.value) || {} as { cod_enterprise: number, name_enterprise: string })
  }

  const onChange = (imageList: ImageListType) => {
    setImages(imageList as never[]);
  };
  
  const handleTitle = (event : React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }
  const handleChangePublic = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPublic(event.target.value)
  };
  const handleDescription = (value : string) => {
    setDescription(value)
  }
  const handleChangeBuilding = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasBuilding(event.target.value)
  };
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
      setOpen(false);
      setIsDelete(false);
  };
  const handleDelete = async () => {
    setIsDelete(true);
      try{
        await api.delete(`/realtor/announcement/${selectedData.id}`,{
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
        })
        setTimeout(function() {
          navigate('/marketing/list-data');
        }, 2500);
      } catch{
        return false
      }
  }

  const setSrcImage = async () => {
    const url = await getUrlAwsS3Realtor(selectedData.thumbnail_url ?? '', selectedData.thumbnail_name ?? '', selectedData.is_public);
    setUrlThumbNail(url);
  }

  useEffect(() => {
    setSrcImage()
  }, [])
  useEffect(() => {
    if (!isValidRelated && hasBuilding.trim().length > 0) {
      setIsValidRelated(true);
    } else if (!isValidRelated && hasBuilding.trim().length === 0) {
      setIsValidRelated(false);
    } 
    if(!isValidOption && isPublic.trim().length > 0){
      setIsValidOption(true);
    } else if (!isValidOption && isPublic.trim().length === 0){
      setIsValidOption(false);
    }
    if(!isValidTitle && title.trim().length > 0){
      setIsValidTitle(true);
    } else if (!isValidTitle && title.trim().length === 0){
      setIsValidTitle(false);
    }
    if(!isValidImage && (images.length > 0 || imageEdit)){
      setIsValidImage(true);
    } else if (!isValidImage && (images.length === 0 || !imageEdit)){
      setIsValidImage(false);
    }
    
  }, [isValidOption, isValidRelated, isPublic, hasBuilding, isValidTitle, title, images, imageEdit, isValidImage])
  useEffect(() => {
    fetchEnterprises().then((data) => {setEnterprises(data)})
  }, [] )
  const onSubmitForm = (event: React.FormEvent, typeSubmit: string) => {
    event.preventDefault();
    let isValidForm = true;

    if (title.trim().length === 0) {
      setIsValidTitle(false);
      isValidForm = false;
    } else {
      setIsValidTitle(true);
    }

    if (description.trim().length === 0) {
      setIsValidDescription(false);
      isValidForm = false;
    } else {
      setIsValidDescription(true);
    }

    if (hasBuilding.trim().length === 0) {
      setIsValidRelated(false);
      isValidForm = false;
    } else {
      setIsValidRelated(true);
    }
  
    if (isPublic.trim().length === 0) {
      setIsValidOption(false);
      isValidForm = false;
    } else {
      setIsValidOption(true);
    }
  
    if(isEdit){
      if(imageEdit || images){
        setIsValidImage(true);
      } else {
        setIsValidImage(false)
        isValidForm = false;
      }
    } else { 
      if (images.length === 0) {
        setIsValidImage(false);
        isValidForm = false;
      } else {
        setIsValidImage(true);
      }
  
    }
    
    if(isValidForm){
      setDisabledButtons(true)
      if(typeSubmit === 'saveDraft' ||typeSubmit === 'publish'){
      Promise.all([
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAnnouncement({
          file: images[0].file,
          thumbnail: images[0].file,
          title: title,
          description: description,
          type: 'announcement',
          is_draft: typeSubmit === 'saveDraft' ? true : false,
          is_public: isPublic === 'publico' ? true : false,
          cod_enterprise: selectedEnterprise.cod_enterprise,
        })
      ])
        .then(() => {
          setToastNotification(true);
          if(typeSubmit === 'saveDraft'){
            setDraftToast(true)
          }
          if(typeSubmit === 'publish'){
            setValidationToast(true)
          }
          
          setTimeout(function() {
         
            navigate('/marketing/list-data');
          }, 2500);
        })
        if(hasBuilding === 'nao'){
          setIsGeneral(true)
          setBreadCrumbsName('COMUNICADOS GERAIS')
        } else {
          setIsGeneral(false)
          setBreadCrumbsName('COMUNICADOS POR EMPREENDIMENTO')
        }
        setTypeList('announcement')
      }
      else if(typeSubmit === 'saveEdit'){
        Promise.all([
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEditAnnouncement({
            id: selectedData.id,
            file:  images && images.length > 0 ? images[0].file : imageEdit,
            thumbnail:  images && images.length > 0 ? images[0].file : imageEdit,
            title: title,
            description: description,
            type: 'announcement',
            is_public: isPublic === 'publico' ? true : false,
            cod_enterprise: hasBuilding  === 'sim' ? (selectedEnterprise.cod_enterprise || selectedEnterprise.enterprise_code) :  null,
          })
        ])
          .then(() => {
            setToastNotification(true);
            setSaveToast(true)
            setTimeout(function() {
              navigate('/marketing/list-data');
            }, 2500);
          
          })
          if(hasBuilding === 'nao'){
            setIsGeneral(true)
            setBreadCrumbsName('COMUNICADOS GERAIS')
          } else {
            setIsGeneral(false)
            setBreadCrumbsName('COMUNICADOS POR EMPREENDIMENTO')
          }
          setTypeList('announcement')
        } 
      else if(typeSubmit === 'publishEdit'){
        Promise.all([
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEditAnnouncement({
            id: selectedData.id,
            file: images && images.length > 0 ? images[0].file : imageEdit,
            thumbnail: images && images.length > 0 ? images[0].file : imageEdit,
            title: title,
            description: description,
            type: 'announcement',
            is_draft: false,
            is_public: isPublic === 'publico' ? true : false,
            cod_enterprise: hasBuilding  === 'sim' ? (selectedEnterprise.cod_enterprise || selectedEnterprise.enterprise_code) : null,
          })
        ])
          .then(() => {
            setToastNotification(true);
            setValidationToast(true)
            setTimeout(function() {
              navigate('/marketing/list-data');
            }, 2500);
          
          })
      }
      if(hasBuilding === 'nao'){
        setIsGeneral(true)
        setBreadCrumbsName('COMUNICADOS GERAIS')
      } else {
        setIsGeneral(false)
        setBreadCrumbsName('COMUNICADOS POR EMPREENDIMENTO')
      }
      setTypeList('announcement')
    }
    
    setToastNotification(false);
    setValidationToast(false);
    setDraftToast(false);
  }
    return(
        <Container>
          {toastNotification && 
        <div id="toast-container2">
        <Toast Icon={validationToast || draftToast || saveToast ? BsCheck2Circle : BiBell} onClose={() => setToastNotification(!toastNotification)} status={validationToast || saveToast ? 'success' : draftToast ? 'draft' : ''} >
          <p>
            {validationToast && 'Seu comunicado foi publicado com sucesso'}
            {draftToast && 'Seu comunicado foi salvo como rascunho'}
            {saveToast && 'Seu comunicado foi salvo com sucesso'}

          </p>
        </Toast>
        </div>
      
      }
            <div>
                <BreadcrumbsDashboard  crumbs={crumbs} />
            </div>
            <p className="title" style={{fontSize: 20, textTransform: 'uppercase'}}>{isEdit2 ? 'Editar': 'Adicionar novo'} Comunicado</p>
            <div className="div-banner">
            
                <div className="top-banner">
                    <div className="esquerda">
                        <p>Banner</p>
                        <p>Será mostrado no início da página do Comunicado</p>
                    </div>
                    <div className="direita">
                        <p>Dimensões: 1216x384px / Área de Segurança: 640x384px</p>
                        <p>Tamanho máximo: 1MB</p>
                    </div>
                </div>
                <div className="image">
                {imageEdit ? 
                <ImageUploading
                  acceptType={['jpg', 'jpeg', 'png']}
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  maxFileSize={1000000}
                >
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  isDragging,
                  dragProps,
                  errors
                }) => (
                
                  <div className="upload__image-wrapper" {...dragProps} >
                  
                    
                      <ImageWrapper>
                
                        
                          <>
                            <img className="" alt="edit" src={imageList[0] ? imageList[0]['data_url'] : urlThumbNail} />
                            <div className="image-item__btn-wrapper">
                
                              <button onClick={() =>{onImageUpload(); setImageEditNew(imageList)}}>
                                <FiEdit width="48" height="48" />
                              </button>
                
                            </div>
                          </>
                        
                      </ImageWrapper>
                    
                    {errors?.maxFileSize && <span>A imagem é muito grande, tente subir um arquivo com no máximo 1 mb.</span>}
                  </div>
                )}
              </ImageUploading>
                : 
                <ImageUploading
                  acceptType={['jpg', 'jpeg', 'png']}
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  maxFileSize={1000000}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    isDragging,
                    dragProps,
                    errors
                  }) => (
                  
                    <div className="upload__image-wrapper" {...dragProps} >
                    
                      {images.length === 0 ?
                      <ButtonAdd onClick={onImageUpload}><AiOutlinePlus />SELECIONAR ARQUIVO DO SEU DISPOSITIVO</ButtonAdd>
                      
                        :
                        <ImageWrapper>
                        
                          {imageList.map((image, index) => (
                            <>
                              <img key={index} className="" alt="add" src={image['data_url']} />
                              <div className="image-item__btn-wrapper">
                          
                                <button onClick={() => onImageUpdate(index)}>
                                  <FiEdit width="48" height="48" />
                                </button>
                          
                              </div>
                            </>
                          ))}
                        </ImageWrapper>
                      }
                      {errors?.maxFileSize && <span>A imagem é muito grande, tente subir um arquivo com no máximo 1 mb.</span>}
                    </div>
                  )}
                </ImageUploading>
                }
                  
                </div>
              </div>  
              {!isValidImage && <p className="error">É obrigatório adicionar uma imagem para o banner</p>}          
              <div className="form"> 
                <FormControl>
                  <div className="form-inputs">
                    <p className="title">Preencha as informações a seguir:</p>
                    <TextField className="titlefield" id="outlined-size-small" label="Título do comunicado" variant="outlined" fullWidth value={title} onChange={handleTitle} inputProps={{maxLength: 50}}/>
                    {!isValidTitle && <p className="error">Campo obrigatório</p>}
                    
                    <TextArea id="outlined-size-small" label="Descrição do comunicado" onChange={handleDescription} />
                    {!isValidDescription && <p className="error">Campo Obrigatório</p>}

                    <div>
                      <p className="title">Esse comunicado é relacionado a um empreendimento?</p>
                        <FormControl required>
                        <RadioGroup>
                        <FormControlLabel value="yes" control={<Radio value='sim' onChange={handleChangeBuilding} checked={hasBuilding === 'sim'}/>} label="Sim"/>
                            <FormControlLabel value="no" control={<Radio value='nao' onChange={handleChangeBuilding} checked={hasBuilding === 'nao'}/>} label="Não (O comunicado será mostrado em Comunicados Gerais)"/>
                        </RadioGroup>
                        </FormControl>
                    </div>
                    {!isValidRelated && <p className="error">Campo obrigatório</p>}

                    {hasBuilding === 'sim' && 
                    <div>
                      <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">{isEdit ? selectedEnterprise.enterprise_name : 'Selecione o empreendimento'}</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedEnterprise.name_enterprise}
                    onChange={handleSelectedEnterprise}
                    label="Selecione o empreendimento"
                    fullWidth
                  >
                    {enterprises.map(enterprise => (
                      <MenuItem key={enterprise.cod_enterprise} value={enterprise.cod_enterprise}>{enterprise.name_enterprise}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                    </div>
                    }
                    
                    <div>
                      <p className="title">Qual o nível de acesso desse comunicado?</p>
                      <FormControl required>
                        <RadioGroup>
                          <FormControlLabel value="Publico" control={<Radio  value='publico' onChange={handleChangePublic} checked={isPublic === 'publico'} />} label="Público - Poderá ser enviado para clientes via mensagem"/>
                          <FormControlLabel value="Privado" control={<Radio  value='privado' onChange={handleChangePublic} checked={isPublic === 'privado'} />} label="Privado - Apenas membros da equipe HM podem visualizar"/>
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {!isValidOption && <p className="error">Campo obrigatório</p>}

                  </div>
                  <div className="sidebar-form">
                    
                    
                  </div>
                </FormControl>
              </div> 
              <div className="buttons">
              {!isEdit2 ?
                <>
                  <ButtonAlt $modal onClick={(event) => onSubmitForm(event, "saveDraft")} disabled={disabledButtons}>Salvar como rascunho</ButtonAlt>
                  <ButtonAlt $primary onClick={(event) => onSubmitForm(event, "publish")} disabled={disabledButtons}>Salvar e Publicar</ButtonAlt>
                </>
                :
                <>
                  <ButtonAlt $primary onClick={(event) => onSubmitForm(event, "saveEdit")} disabled={disabledButtons}>Salvar Alteraçoes</ButtonAlt>
                  {selectedData.is_draft && 
                    <ButtonAlt $primary onClick={(event) => onSubmitForm(event, "publishEdit")} disabled={disabledButtons}>Salvar e Publicar</ButtonAlt>
                  }
                  <ButtonAlt $modal onClick={handleOpen}>Arquivar</ButtonAlt>
                </>
                }
              </div>
              <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    {!isDelete ? 
                    <div className="modalcontent">
                        <IconWrapper>
                            <MdOutlineDescription />
                        </IconWrapper>
                        <BoldP>Tem certeza de que deseja arquivar este comunicado?</BoldP>
                        <ButtonGroup>
                            <ButtonArticle $primary $modal onClick={handleDelete}>Excluir</ButtonArticle>
                            <ButtonArticle $modal onClick={handleClose}>Cancelar</ButtonArticle>
                        </ButtonGroup>
                    </div>
                    :
                    <div>
                         <IconWrapper>
                            <MdOutlineDescription />
                        </IconWrapper>
                        <BoldP>O comunicado foi arquivado com sucesso.</BoldP>
                        <ButtonArticle $confirmation onClick={handleClose}>Voltar</ButtonArticle>
                    </div>
                    }
                    
                </Box>     
            </Modal>
        </Container>
    )
}

export default ManageContent