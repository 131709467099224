import { Body, Box, Close, Container, Content, Title } from './style';
import { IoMdClose } from 'react-icons/io';

interface Props{
    body: JSX.Element;
    title: string;
    openClose: [boolean, (a: boolean) => void];
}

const Modal = ({ body, title, openClose }: Props) => {
    return (
        <Container open={openClose[0]}>
            <Body>
                <Box>
                    <Title>
                        <h2>{title}</h2>
                    </Title>
                    <Close onClick={() => openClose[1](false)}>
                        <IoMdClose />
                    </Close>
                </Box>
                <Content>
                    {
                        body
                    }
                </Content>
            </Body>
        </Container>
    )
}

export default Modal;