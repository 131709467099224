import { Card, CardContent } from "@material-ui/core";
import { Container, ms } from "./styles";
import { FiPlusCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "../../context/DashboardContext";

const Marketing = () => {
    const materialStyles = ms()
    const navigate = useNavigate()
    const { setIsGeneral, setBreadCrumbsName, setTypeList } = useDashboard()

    const handleNavigation = (isGeneral: boolean, name: string, type: string) => {
        setIsGeneral(isGeneral)
        setBreadCrumbsName(name)
        setTypeList(type)
        navigate('/marketing/list-data')
    }
    return (
        <Container>
        {/* Arquivos */}
          <Card className={materialStyles.card}>
            <CardContent className={materialStyles.cardContent}>
                <div>
                    <h1>ARQUIVOS</h1>
                    <p>Repositório de arquivos para suporte à vendas dos corretores</p>
                </div>
                <div className={materialStyles.buttonDiv}>
                    <button onClick={() => handleNavigation(false, 'ARQUIVOS POR EMPREENDIMENTO', 'file')}>
                        ARQUIVOS POR EMPREENDIMENTO
                    </button>
                    <button onClick={() => handleNavigation(true, 'ARQUIVOS GERAIS', 'file')}>
                        ARQUIVOS GERAIS
                    </button>
                    <a href='/marketing/manage-content/archive'>
                        <FiPlusCircle/>
                        ADICIONAR NOVO
                    </a>
                </div>
            </CardContent>
          </Card>

        {/* Missões */}
          <Card className={materialStyles.card}>
            <CardContent className={materialStyles.cardContent}>
                <div>
                    <h1>MISSÕES</h1>
                    <p>Campanhas de vendas</p>
                </div>
                <div className={materialStyles.buttonDiv}>
                    <button onClick={() => handleNavigation(false, 'MISSÕES POR EMPREENDIMENTO', 'mission')}>
                        MISSÕES POR EMPREENDIMENTO
                    </button>
                    <button onClick={() => handleNavigation(true, 'MISSÕES GERAIS', 'mission')}>
                        MISSÕES GERAIS
                    </button>
                    <a href='/marketing/manage-content/mission'>
                        <FiPlusCircle/>
                        ADICIONAR NOVO
                    </a>
                </div>
            </CardContent>
          </Card>

          {/* COMUNICADOS */}
          <Card className={materialStyles.card}>
            <CardContent className={materialStyles.cardContent}>
                <div>
                    <h1>COMUNICADOS</h1>
                    <p>Comunicação para os corretores</p>
                </div>
                <div className={materialStyles.buttonDiv}>
                    <button onClick={() => handleNavigation(false, 'COMUNICADOS POR EMPREENDIMENTO', 'announcement')}>
                        COMUNICADOS POR EMPREENDIMENTO
                    </button>
                    <button onClick={() => handleNavigation(true, 'COMUNICADOS GERAIS', 'announcement')}>
                        COMUNICADOS GERAIS
                    </button>
                    <a href='/marketing/manage-content/announcement'>
                        <FiPlusCircle/>
                        ADICIONAR NOVO
                    </a>
                </div>
            </CardContent>
          </Card>

        </Container>
    )
}

export default Marketing;