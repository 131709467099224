/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { BsFillPatchCheckFill, BsFillShieldLockFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"
import Button from "../../components/Button";
import Form from "../../components/Login/Form";
import Input from "../../components/Login/Input";
import Spinner from "../../components/Spinner";
import { ReturnApi } from "../../interfaces/ReturnApi";
import api from "../../services/api";
import { Container } from "./styles";

const ForgotPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [wasSend, setWasSend] = useState(false);
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);

        const send = await requestForgotPassword(email);

        setIsLoading(false);

        if (send.status === "error") {
            if (send.message === "400") {
                toast.error('O endereço de email é inválido, contate o suporte');
            } else {
                toast.error('Ocorreu um erro, tente novamente mais tarde')
            }
            return;
        }
        
        setEmail('');
        setWasSend(true);
        toast.success('Enviado com sucesso!')
    }

    const requestForgotPassword = async (email: string): Promise<ReturnApi<string>> => {
        try {
            const { data } = await api.post('/forgot-password', {
                email,
            });

            return{
                status: "success",
                message: "",
                data
            }
        } catch (error: any) {
            return{
                status: "error",
                message: String(error?.response.status),
                data: ""
            }
        }
    }

    const backToLogin = () => {
        navigate('/login');
    }

    return (
        <Container>
            <div className="content">
                <div className="brand">
                    <div>
                        <img src="/images/loog-hm-estelar-white@2x.png" />
                        <span className="title">Acesso Administrativo</span>
                    </div>
                </div>
                <div className="form">
                    <Form onSubmit={handleSubmit}>
                        {
                            wasSend ? (
                                <>
                                    <h3 className="success"><BsFillPatchCheckFill /></h3>
                                    <span>Já enviamos um email com todas as instruções necessárias, verifique sua caixa de entrada</span>
                                    <Button onClick={backToLogin}>Fazer login</Button>
                                </>
                            ) : (
                                <>
                                    <h3 className="lock-pass"><BsFillShieldLockFill /></h3>
                                    <span>Para redefinir sua senha insira seu endereço de email e siga as instruções</span>
                                    

                                    <Input onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email" type="email" name="email" required />

                                    <Button disabled={isLoading || wasSend} type="submit">
                                        {
                                            !isLoading ? 'Enviar' : <Spinner type="secondary" />
                                        }
                                    </Button>
                                </>
                            )
                        }
                    </Form>
                </div>
            </div>
            <div className="footer">
                <span>
                    Todos os direitos reservados &copy; HM Engenharia {new Date().getFullYear()}.
                </span>
            </div>
        </Container>
    )
}

export default ForgotPassword;