import { ResponseUsers } from "../../../interfaces/ResponseUsers";
import Button from "../../Button";
import Spinner from "../../Spinner";
import { ContentModal } from "./style";

interface Props{
    data: ResponseUsers | undefined;
    handleSubmit: (e: any) => Promise<void>;
    handleChange: (a: string, b: string) => void;
    isLoading: boolean;
}

export const ContentModalUpdatePassword = ({ data, handleChange, handleSubmit, isLoading }: Props) => {
    return(
        <ContentModal>
            <h3>
                Redefinir senha de <strong>{ data ? data.name : 'usuário' }</strong>
            </h3>
            <form onSubmit={handleSubmit}>
                <label>Nova senha</label>
                <input onChange={(e) => handleChange(e.target.value, "pass")} required minLength={8} type="password" name="password" />

                <label>Confirme a senha</label>
                <input onChange={(e) => handleChange(e.target.value, "confirm")} required minLength={8} type="password" name="confirm" />

                <Button type="submit" disabled={isLoading}>
                    {
                        !isLoading ? 'Salvar' : <Spinner type="secondary" />
                    }
                </Button>
            </form>
        </ContentModal>
    );
}