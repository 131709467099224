import styled from "styled-components";

export const Container = styled.main`
  flex: 1;
  padding: 0 1rem;
  overflow-y: auto;
`;

export const FilterParams = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  > div {
    padding: 0 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    label{
      color: var(--blue);
      margin-right: 0.5rem;
      white-space: nowrap;
    }
  }

  .date-filter{
    input[type = "date"]{
      padding: 0.5rem 0.8rem;
      border: none;
      color: var(--blue);
      border-radius: 0.24rem;
    }

    div.react-datepicker-wrapper{
      display: flex;
    }

    div.react-datepicker__input-container{
      > input{
        min-height: 34px;
        padding: 0.5rem;
        border: none;
        color: var(--blue);
        border-radius: 0.24rem;
        border-radius: 1.5rem;
        background:  rgba(160, 164, 177, 0.10);
      }
    }
  }

  .coligada-filter{
    select{
      padding: 0.5rem 0.8rem;
      border: none;
      color: var(--blue);
      border-radius: 0.24rem;

      option{
        padding: 0.4rem;
      }
    }
  }

  .fetch{
    button{
      background-image: var(--button-secondary);
    }
  }

  @media (max-width: 768px) {    
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    align-items: baseline;

    > div {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 0;
      height: 72px;

      input, select, option{
        width: 100%;
        max-width: 12rem;
      }

      label{
        margin: 0 0 0.5rem 0;
      }
    }

    .date-filter{
      grid-area: filter_date;
    }

    .coligada-filter{
      grid-area: filter_coligada;
    }
    
    .fetch{
      grid-area: fetch;
      align-items: center;
    }
  }
`;